.custom-column {
  border-radius: 10px; /* Custom border radius */
  justify-content: center;
}

.border-custom {
  border-color: #3498db; /* Same border color as custom-column */
}

.plans-green {
}
