.not-approved-card {
  max-width: 50rem;
}

.not-approved-form {
  min-width: 12.5rem;
}

.brand-logo {
  margin-bottom: 2rem;
}

.name-container {
  margin: auto;
}

.name-welcome {
  font-size: 1.25rem;
  margin-bottom: 1.875rem;
  text-transform: capitalize;
}

.welcome-last-step {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.welcome-last-step-subtitle {
  color: #5f5f5f;
}

.last-step-title-container {
  padding: 0px 1.5rem;
  margin-bottom: 1.875rem;
}

.finish-button:disabled {
  background: gray !important;
  cursor: not-allowed;
  border-color: gray !important;
}

.option-container {
  width: 100%;
  height: 3.125rem;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
}

.interest-option {
  color: #5f5f5f;
  user-select: none;
}

.interest-active {
  border-color: #4987be;
}

.interest-not-active {
  border-color: #c1c1c1;
}

.register-navigation-buttons {
  padding: 0.4rem 1.6rem;
}
