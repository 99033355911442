.qualitative-filters-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 32.5rem;
  max-width: 95%;
  height: calc(100vh - 4rem);
  z-index: 11;
  background-color: #fff;
  box-shadow: -0.375rem 0 0.5rem #00000029;
  transform: translateX(calc(100% + 2rem));
  transition: transform 0.5s ease;
  padding: 3rem 2rem;
}

.qualitative-filters-container[data-opened='true'] {
  transform: translateX(0);
}

.qualitative-testgroup-dropdown-placeholder {
  color: #424242;
}

.qualitative-testgroup-dropdown {
  border: 1px solid #4987be !important;
  border-radius: 7px !important;
}

.qualitative-filters-applied {
  background-color: #bcbcbc;
  border-radius: 1.5rem;
  height: 1.375rem;
}

.qualitative-filters-applied-text {
  padding-left: 0.625rem;
  letter-spacing: 0.05rem;
}

.qualitative-filters-applied-cross {
  padding: 0 0.625rem;
}

.qualitative-filter-extreme,
.qualitative-filter-balanced {
  height: 2rem;
  width: 50%;
}

.qualitative-filter-extreme:first-child {
  border-right: 1px solid #757575;
  width: 46%;
}

.qualitative-filters-radio-button {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  border: 2px solid #757575;
}

.qualitative-filters-clear-button {
  color: #4987be;
  background: none;
  border: 1px solid #4987be;
  border-radius: 1.5rem;
  padding: 0.375rem 1rem;
}

.qualitative-filters-options-container[data-active='false'] {
  .qualitative-filter-extreme {
    cursor: default !important;
  }

  .qualitative-filter-extreme:first-child {
    border-right: 1px solid #bcbcbc;
  }

  .qualitative-filters-radio-button {
    border: 2px solid #bcbcbc;
  }

  .qualitative-filters-text {
    color: #bcbcbc;
  }
}

.qualitative-filter-balanced[data-active='false'] {
  cursor: default !important;

  .qualitative-filters-radio-button {
    border: 2px solid #bcbcbc;
  }

  .qualitative-filters-text {
    color: #bcbcbc;
  }
}
