.upload-files-input-separator {
  height: 0.7rem;
  width: 0.07rem;
  margin: 0.2rem 0.5rem;
  background-color: #41479b;
}

.upload-files-image-uploaded {
  max-width: 24rem;
  max-height: 12rem;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 0 0.25rem 0 #00000040;
  border-radius: 0.5rem;
}

.upload-files-uploaded-separator {
  width: 100%;
  border-bottom: 1px solid #bcbcbc;
}

.upload-files-image-open-button {
  margin-left: 1.5rem;
  background: none;
  border: none;
  color: #303030cc;
}