.time-input-container {
  width: 8.125rem;
}

.time-input-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.time-input {
  width: 1.25rem;
}

.time-input-separators {
  padding: 0 0.065rem 0.065rem 0.065rem;
}
