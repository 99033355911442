.dropdown-button-options-container[data-open='true'] {
  max-height: 10rem;
  width: 10rem;
  z-index: 4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dropdown-button-options-container[data-open='false'] {
  display: none;
}
