@import '../../variables.scss';

.text-buttons {
  line-height: 1.063rem;
  letter-spacing: 0.025rem;
  flex: 1;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  margin: 1rem 0rem;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.theme-input-border {
  border: 1px solid #eff0f4;
}
.w-92-5 {
  width: 92.5%;
}

.imageButton {
  width: 2rem;
  margin-right: 0.5rem;
  height: auto;
  align-self: center;
}

.border-none {
  border: none;
}

.additional-image {
  display: flex;
  align-self: flex-start;
}

.empty-buttons {
  flex: 1;
  background-color: white;
  border: none;
}

.bookmarker {
  width: 5rem;
  height: auto;
  top: -1.05rem;
  right: -0.5rem;
  position: absolute;
}

.flex-1 {
  flex: 1;
}

.color-grey-1 {
  color: $grey-1 !important;
}

.color-white {
  color: white !important;
}

.bottom-border {
  border: none;
  border-bottom: 2px solid $grey-2;
  background-color: transparent;
  resize: none;
  border-radius: 0.25rem;
}

.border-grey-2 {
  border: 2px solid $grey-2;
  border-radius: 0.25rem;
  background-color: transparent;
  resize: none;
}

.svg-selected {
  svg:not(.ai-button) path {
    fill: white;
  }
  &.ai-button {
    path {
      stroke: white;
      fill: white;
    }
  }
}

.question-to-add-container {
  background-color: #f5f6f6;
  padding: 1rem 2rem;
  font-weight: 500 !important;

  p,
  span,
  input,
  textarea {
    color: $grey-1;
  }
}

.checkbox-files-item {
  background: transparent;
  border-color: $grey-2;
}

.disabled-icon-add-questions {
  // w-min-content absolute-top-right position-absolute m-3
  width: 2rem;
  height: 2rem;
  // margin-right: 0.5rem;
  align-self: center;
  position: absolute;
  right: 0;
  transform: translateX(50%) translateY(30%);
  z-index: 2;
  box-shadow: -3px 5px 6px rgba(0, 0, 0, 0.125);
}

textarea:focus::placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}
