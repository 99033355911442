@import '../../variables.scss';

.searches-button-container {
  max-width: 63rem;
  margin: auto;
}

.active-searches-container {
  width: 88% !important;
}

.color-grey-1 {
  color: $grey-1;
}

.color-blue-dark {
  color: $blue-dark;
  text-decoration: underline;
}
