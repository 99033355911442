.button-screening-import {
  background: #ffffff;
  border: solid 1px #4987be;
  border-radius: 2rem;
  padding: 0.4rem 0rem;
}

.button-screening-import-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #4987be;
  white-space: pre-line;
}

.custom-file-button {
  max-width: 25.375rem;
  max-height: 2.375rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.063rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(239, 240, 244, 1);
  color: rgba(24, 44, 76, 1);
}
