.qualitative-result-bar {
  height: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}

.qualitative-result-point {
  height: 24px;
  width: 24px;
  background-color: #4987be;
  display: inline-block;
  border-radius: 50%;
  transition: 0.3s;
}

.qualitative-result-bar-color-1 {
  background-color: #d9d9d9;
}

.qualitative-result-bar-color-2 {
  background-color: #b3b3b3;
}

.qualitative-testgroup-select {
  width: 19.625rem;
  border-color: #4987be !important;
}

.qualitative-result-bar-color-3 {
  background-color: #4987be !important;
}

.qualitative-extreme-selected {
  color: #4987be;
}
