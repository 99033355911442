.parent {
  width: 95%;
  overflow: hidden;
  margin: auto;
  padding: 1rem;
}
.slider-container {
  /* if you want to have a scrollbar, you can add overflow here */
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem;
  padding: 0.188rem;
  scroll-behavior: smooth;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.btn {
  cursor: pointer;
  text-align: center;
  color: white;
  border-radius: 50%;
}

.prev {
  left: 1rem;
}

.next {
  right: 1rem;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.child {
  padding: 0.313rem 1.25rem;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-left: 0.278rem;
  border-radius: 8px;
}

.child-disabled {
  color: #828282;
  border: 1px solid #828282;
}

.child-selected {
  border: 2px solid #4987be;
  color: #182c4c;
}

.nav-button-disabled {
  filter: grayscale(1);
  cursor: not-allowed !important;
}
