.results-right-column {
  border-top: 1px solid #e0e0e0;
}

.percent-result-text {
  font-size: 1.75rem;
}

@media (min-width: 768px) {
  .results-left-column {
    width: 70%;
  }

  .results-right-column {
    width: 30%;
    border-left: 1px solid #e0e0e0;
    border-top: none;
  }
}
