@import '../../variables.scss';

.percentage-input-width {
  width: 4rem;
}

.disabled-weight-input {
  color: rgba(75, 75, 75, 0.25);
  border: 0.065rem solid rgba(75, 75, 75, 0.25);
  border-radius: 8px;
}

.testgroup-description-border-4step {
  border: 0.2rem solid #f4f4f4;
  border-radius: 0px;
}

.weight-input-custom-width {
  width: 5.125rem;
}

.qualitative-label {
  color: #828282;
}

.qualitative-label-width {
  width: 8.125rem;
}

.details-button-testgroup {
  display: flex;
  color: $blue-principal;
  border: 1px solid $blue-dark !important;
  background-color: transparent;
  padding: 0.2rem 1.2rem;
  height: auto;
  font-size: 0.8rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}

.testgroup-listitem-container {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;
  min-height: 10rem;
}

.tg-number-text {
  font-size: 1.25rem;
  font-weight: 500;
  color: $grey-2;
}

.wrong-weight {
  color: $orange-error !important;
  border-color: $orange-error !important;
}

.list-item-card {
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  border: none;
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  z-index: 0 !important;
}

.list-item-card.flipped {
  transform: rotateY(180deg);
}

.list-item-card-front,
.list-item-card-back {
  display: flex;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  grid-area: 1 / 1 / 2 / 2;
}

.list-item-card-front {
  z-index: 2;
}

.list-item-card-back {
  transform: rotateY(180deg);
  z-index: 1;
  background: $grey-3;
  border-radius: 8px;
  cursor: pointer;
}

.testformat-icon {
  width: 1rem;
  height: 1rem;
  color: $grey-2 !important;
}

.testgroup-list-item-menu {
  display: flex;
  width: max-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 100%;
  right: 0;
  opacity: 0;
  padding: 0.5rem;
  background: $grey-3;
  transition: opacity 0.1s ease-in-out !important;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 0.2rem;
  gap: 0.5rem;
  z-index: -2 !important;

  &.show-menu {
    opacity: 1;
    z-index: 1050 !important;
  }
}

.border-grey-rounded {
  border-radius: 0 8px 8px 0;
}
