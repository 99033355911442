.screening-completion-dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.completed-dot-bg {
  background-color: #2b9f5a;
}

.in-progress-dot-bg {
  background-color: #ddbc67;
}

.invited-dot-bg {
  background-color: #d9d9d9;
}

.invited-list-titles,
.invited-list-skill-name {
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #424242;
}

.invited-page-container {
  max-width: 65rem;
}

.invited-list-name-width {
  width: 25%;
}

.invited-list-name-indicator-width {
  width: 25%;
}

.profiles-invited-list-container[data-size~='0'] {
  .invited-list-name-width {
    width: 100%;
  }

  .invited-list-screening-width {
    width: 3rem;
  }

  .invited-list-score-width {
    width: 2rem;
  }

  .invited-list-date-width {
    width: 3.5rem;
  }

  .invited-list-process-width {
    width: 4rem;
  }

  .invited-list-status-width,
  .invited-list-status-dropdown-width {
    width: 2.5rem;
  }
}

.profiles-invited-list-container[data-size~='600'] {
  .invited-list-name-width {
    width: 25%;
  }
}

.profiles-invited-list-container[data-size~='700'] {
  .invited-list-screening-width {
    width: 4.5rem;
  }

  .invited-list-score-width {
    width: 3.5rem;
  }

  .invited-list-process-width {
    width: 5rem;
  }

  .invited-list-status-width {
    width: 7.5rem;
  }

  .invited-list-status-dropdown-width {
    width: 9rem;
  }
}

.profiles-invited-list-container[data-size~='900'] {
  .invited-list-screening-width {
    width: 5rem;
  }

  .invited-list-score-width {
    width: 7rem;
  }

  .invited-list-date-width {
    width: 4.5rem;
  }
}

.ant-btn,
.ant-btn:hover,
.ant-btn:focus {
  border-radius: 1rem;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #58dda6;
  border-color: #58dda6;
}

.invitation-status-modal-container span {
  font-size: 1.1rem;
}

.profiles-list-item-container-search {
  border: 0.5px solid #4987be;
  border-radius: 8px;
  font-size: 0.875rem;
  min-height: 3.5rem;
  line-height: 1rem;
  color: #272727;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .ml-md-6 {
    margin-left: 7.5rem !important;
  }
  .w-indicators-container {
    width: 50%;
  }
}

@media (max-width: 990px) {
  .w-indicators-container {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .w-indicators-container {
    width: 80%;
  }
}
