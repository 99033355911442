.detail-container-content {
  max-width: 67rem;
  padding: 1.5rem 3rem !important;
  margin: 0 auto;
  height: fit-content;

  @media screen and (max-width: 1180px) {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}

@media (min-width: 576px) {
  .invited-profile-data-card {
    width: 20%;
    min-width: 22rem;
  }

  .invited-profile-stages-card {
    width: 35%;
    min-width: 30rem;
  }
}

.proctoring-container {
  min-height: calc(100vh - 5rem);
}

.incidences-quantity-container {
  border-radius: 50%;
  height: 1.375rem;
  width: 1.375rem;
  font-size: 0.625rem;
  font-weight: 700;
  background-color: #fee2e2;
  color: #000000;
}

.proctoring-meausure-logo-container {
  width: 1.5rem;
  display: flex;
  margin-right: 0.625rem;
}

.results-overflow-container-height {
  max-height: calc(100vh - 35rem);
  min-height: 12.5rem;
}

.content-container {
  position: relative;
  top: -0.126rem;
  border: 2px solid #4987be;
  display: flex;
}

.proctoring-gray-text {
  color: #4b4b4b;
}

.arrow-button-container {
  padding: 0 1rem;
  width: 3.374rem;
}

.timestamp-container {
  flex: 1;
  display: inline-block;
}

.notification-container {
  flex: 2;
  display: inline-block;
}

.item-detail-title {
  display: inline-block;
  font-size: 0.875rem;
}

.spinner-loading-list {
  width: 1.5rem;
  height: 1.5rem;
}

.go-back-button {
  width: fit-content;
}
