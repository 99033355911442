@import '../../variables.scss';

.invite-members-form-container {
  max-width: 35rem;
  width: 100%;
  height: 100%;
}

.invite-members-input-delete {
  background: none;
  border: none;
}

.members-invite-add-members-button {
  background: none;
  border: none;
  color: #757575;
  line-height: 1;
  text-align: left;
}

.members-invite-submit-button {
  background: $blue-principal !important;
  border: none;
}

.members-invite-submit-button.disabled {
  background: $grey-2 !important;
  border: none;
  cursor: not-allowed;
}

.dropdown-input {
  border: 1px #c9c9c9 solid;
  outline: none;
  padding: 0.3rem 0;
  max-height: 2.225rem;
  max-width: 15rem;
  font-size: 0.875rem;
}

.column-gap {
  column-gap: 0.5rem;
}
