.badge {
  border-radius: 2px;
  line-height: 0.938rem;
  letter-spacing: 0.07em;
}

.badge-warning {
  color: #926d36;
  background: #ffd0185c;
}

.badge-success {
  color: #3d8c6b;
  background: #42ff775c;
}
