.show-contact-modal {
  transition: all 0.2s;
  opacity: 1;
}

.not-show-contact-modal {
  transition: all 0.2s;
  width: 0;
  opacity: 0;
}

.contact-gmail-icon {
  width: 1.5rem;
  height: 1.5rem;
}
