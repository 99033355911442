@import '../../_variables.scss';

.titleSlider {
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-container {
  display: flex;
  align-items: center;
}

.sliderLabel {
  flex: 1;
  text-align: left;
}

.inputSlider {
  flex: 1.5;
  margin: 0 10px;

  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  /* Altura general del track */

  /* Track completo */
  &::-webkit-slider-runnable-track {
    height: 5px;
    /* Altura del track */
    background: linear-gradient(to right, $blue-principal var(--slider-value), $grey-2 0%);
    border-radius: 5px;
  }

  &::-moz-range-track {
    height: 5px;
    background: linear-gradient(to right, $blue-principal var(--slider-value), $grey-2 0%);
    border-radius: 5px;
  }

  &::-ms-track {
    height: 5px;
    background: transparent;
    /* MS necesita manejo distinto */
    border-color: transparent;
    border-width: 8px 0;
    color: transparent;
  }

  /* Estilizando el thumb para ejemplo completo */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $blue-principal;
    cursor: pointer;
    margin-top: -6px;
    /* Alinear con track */
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $blue-principal;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $blue-principal;
    cursor: pointer;
  }
}

.sliderValue {
  flex: 0.2;
  text-align: right;
}

.ResetSlider {
  padding: 8px 16px;
  background-color: $blue-principal;
  border: none;
  border-radius: 0px;
  color: white;
  cursor: pointer;
}

.ResetSlider:hover {
  background-color: $blue-dark;
}
