@import '../../variables.scss';

.test-group-name-input {
  border: 1.5px #bbbbbb solid;
  outline: none;
  min-width: 39.003rem;
}

.workspace-id-input {
  border: 1.5px #bbbbbb solid;
  outline: none;
  min-width: 4rem;
}

.screening-switch {
  max-width: 20rem;
  height: 6.749rem;
  padding: 0.938rem 1.25rem;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.technologies-dropdown {
  min-width: 6rem;
  max-width: 13rem;
}

.cursor-wait {
  cursor: wait;
}

.error-icon-color {
  color: $orange-error;
}

.bg-button-modal-close {
  background-color: #f44336;
  border-radius: 16px;
  padding: 0.375rem 2.5rem;
  gap: 0.25rem;
}

.modal-delete-tg-container {
  width: 100%;
  max-width: 31rem;
}

.min-height-text-area {
  min-height: 4rem;
}

.max-height-text-area {
  max-height: 12.5rem;
}

.min-height-textarea-description {
  min-height: 6.75rem;
}

.min-height-add-question {
  min-height: 4.5rem;
}

.testgroup-abm-message {
  max-width: none !important;
  width: auto;
  .ant-notification-notice-message {
    white-space: nowrap !important;
    line-height: 1.8rem !important;
  }
}

.bg-violet {
  background-color: #7643e4;
}

.bg-button-modal-massive-accept {
  background-color: #7643e4;
  border-radius: 16px;
  padding: 0.375rem 2.5rem;
  gap: 0.25rem;
}

.ant-slider-track {
  background-color: #4987be !important;
}

.antd-group-slider {
  gap: 200px;
}

.antd-group-slider__footer .ant-tooltip-disabled-compatible-wrapper .ant-btn {
  width: 100%;
  color: white;
  font-style: normal;
}

.ant-btn-background-ghost.ant-btn-primary {
  width: 100%;
  color: white;
  font-style: normal;
}

.antd-group-slider__btn-remove-range {
  width: 100%;
}
.antd-group-slider__btn-add-range {
  width: 100%;
}

.antd-group-slider-item__description {
  display: flex;
  align-items: center;
}
.antd-group-slider-item__description-left-text {
  margin-right: 0.25rem;
  white-space: nowrap;
}
.antd-group-slider-item__description-right-text {
  margin-left: 0.25rem;
  white-space: nowrap;
}
.ant-slider-handle.ant-slider-handle-1 {
  /* make left slider slim */
  border-radius: 0%;
  width: 0px;
}

.ant-tooltip-inner {
  display: none !important;
}
.ant-tooltip-arrow {
  display: none !important;
}

.max-char-input-create-testgroup {
  color: $blue-principal;
  line-height: 1.25rem;
}

.ql-editor h3 {
  font-size: 1.5rem;
  font-weight: inherit;
}
