.login-button {
  padding: 0.5rem 0.875rem;
  background: #4987be;
  border-radius: 20px;
}

.login-button-text {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0rem 0.5rem;
}
