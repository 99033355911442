.input-custom-new-style {
  border-radius: 0.5rem;
  border: 1px solid #eff0f4;
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.1);
  font-weight: 600;
  color: #828282;
}

.border-red-500 {
  border: 1px solid rgb(239 68 68);
}
.border-gray-300 {
  border: 1px solid rgb(209 213 219);
}

.w-350 {
  width: 250px !important;
}
