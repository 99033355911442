.home-profile-img {
  border-radius: 9999px;
  width: 8.375rem;
  height: 8.375rem;
  object-fit: contain;
  border: 0.5px solid #a8a8a8;
}

.home-plan-image {
  position: absolute;
  top: -18%;
  right: -3%;
  width: 8.375rem;
  height: 8.375rem;
  object-fit: contain;
  padding: 0.8rem;
  background-color: #fff;
  border-radius: 100%;

  @media (max-width: 992px) {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    padding: 0;
    width: 6.25rem;
    height: 6.25rem;
  }
}

.complete-profile-button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-self: flex-end;
  // border-radius: 1rem;
  margin-right: 5.625rem;
  border: 1px solid #4987be;
  color: #4987be;
  cursor: pointer;
}

.complete-profile-button-recommended {
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  align-self: flex-end;
  // border-radius: 1rem;
  margin-right: 5.625rem;
  color: #fff;
  background-color: #4987be;
  cursor: pointer;
}

.home-progress {
  margin: 0.5rem 0rem 0.5rem 0rem;

  .progress-training-bg {
    height: 0.6875rem !important;
  }

  .progress-training {
    height: 0.6875rem !important;
  }
}

.custom-margin-home-progress {
  margin-top: 0.45rem;
}

.completed-percentage-text {
  color: #5e5e5e;
}

.completed-percentage-text-red {
  color: #f44336;
}

.text-link {
  color: #3f47cf;
  text-decoration: underline;
}

.tooltip-visual-home {
  z-index: 200;
}

.width-container-home-profile {
  width: 50%;
}

@media (max-width: 992px) {
  .width-container-home-profile {
    width: 100%;
  }
}

.container-flex-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
}

.position-profile-name {
  margin-left: 1rem;
}

.position-progress-bar {
  width: 40%;
  margin: 0 auto 0 auto;
  padding-left: 1.5rem;
  align-self: center;
}

.position-percentage-text {
  margin-right: 1rem;
}

.position-profile-button {
  position: absolute;
  top: 2.8rem;
  margin-right: 1rem;
}

.position-profile-button-recommended {
  position: absolute;
  top: 2.8rem;
  left: -2rem;
  margin-right: 1rem;
}

.size-profile-img {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  border: 0.5px solid #a8a8a8;
}

@media only screen and (max-width: 1000px) {
  .container-flex-profile {
    flex-direction: column;
    height: auto;
  }

  .position-progress-bar {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
  }

  .position-profile-name {
    margin-right: auto;
    margin-left: 2.5rem;
  }

  .position-percentage-text {
    margin-top: 1rem;
    margin-right: 0;
  }

  .position-profile-button-recommended .position-profile-button {
    margin-top: 1rem;
    margin-right: 0;
    position: inherit;
    margin: auto 2rem 1rem;
  }
}

@media only screen and (max-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
}
