.container {
  overflow: hidden;
  padding: 0px;
  display: flex;
  justify-content: center;
  max-height: 100vh;
}

.children-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.875rem;
}
