@import '../../variables.scss';

.test-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25.6rem, 1fr));
  gap: 1rem;

  &.create-evaluation-view {
    gap: 0 !important;
  }
}
@media (max-width: 1600px) {
  .test-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1590px) {
  .test-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1150px) {
  .test-grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.full-width-grid {
  grid-column: 1 / -1;
}

.test-grid-container-tests,
.test-grid-container-create-screening {
  height: 24.125rem;
}

.horizontal-loader-height {
  height: 1rem;
}

.total-number-text {
  color: $blue-principal;
}

.select-color {
  color: $blue-dark;
}

.tests-bg {
  background-color: rgba(65, 71, 155, 0.05);
}

.tests-search-input-full,
.tests-search-input-empty {
  border: 0.125rem solid;
  transition: 0.2s;
}

.tests-search-input-full {
  border-color: $blue-principal;
  transition: 0.2s;
}

.tests-search-input-empty {
  border-color: #828282;
  transition: 0.2s;
}

.empty-state-container {
  width: 17.625rem;
  height: 17.625rem;
}

.test-button-filters {
  border-radius: 8px;
  border: 1px solid #787878;
}

.test-button-filters-selected {
  border: 1px solid $blue-principal;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
}

.test-text-filters {
  color: #828282;
  font-weight: 700;
  font-size: 0.875rem;
}

.test-text-filters-selected {
  font-size: 0.875rem;
  color: #182c4c;
  font-weight: 700;
}

.container-test-role {
  background: $blue-principal;
  border-radius: 8px;
}

.text-test-role {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(48, 48, 48, 0.8);
}

.button-filter {
  display: inline-block;
  padding: 2rem 2rem;
  text-align: center;
}

.drops-filter {
  display: inline-block;
  text-align: center;
  position: none;
  flex-direction: row;
  width: 8rem;
}
