.error-container {
  height: calc(100vh - 7rem);
}

.error-title-color {
  color: #222222;
}

.error-subtitle {
  color: #828282;
  max-width: 90%;
  width: 64rem;
  white-space: pre-line;
}

.error-button {
  padding: 0.565rem 1rem;
  background: #4987be;
  border-radius: 1rem;
}

.error-button-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
}

.error-button-secondary {
  padding: 0.565rem 1rem;
  background: #ffffff;
  border: 2px solid #4987be;
  border-radius: 1rem;
}

.error-button-secondary-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #4987be;
  margin: 0rem 0.5rem;
}
