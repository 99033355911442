@import '../../variables.scss';

.test-name-max-width {
  max-width: 20rem;
}

.level-question-div {
  color: $blue-principal;
  background: #4987be32;
  font-size: 0.8rem;
  border-radius: 2px;
}

.theme-question-div {
  color: #f57805;
  background: #ffceaa;
  font-size: 0.8rem;
  border-radius: 2px;
}

.listening-question-div {
  color: #3d8c6b;
  background: #cae8db;
  font-size: 0.8rem;
  border-radius: 0.125rem;
}

.code-question-div {
  color: #b360c8;
  background: #e0cce6;
  font-size: 0.8rem;
  border-radius: 0.125rem;
}

@media screen and (min-width: 992px) {
  .test-name-max-width {
    max-width: 25rem;
  }
}

@media screen and (min-width: 1280px) {
  .test-name-max-width {
    max-width: 35rem;
  }
}

.bg-grey-3 {
  background-color: $grey-3;
}
