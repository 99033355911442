.content-min-height {
  min-height: calc(100vh - 80px);
}

.introjs-fixedTooltip {
  left: 1.0735rem !important;
}

.not-accepted-terms-and-conditions-modal-container {
  width: 100vw;
  height: 100vh;
}

.modal-bg-image {
  height: 100%;
  background-image: url('../../assets/background/partners-home.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
}
