.invited-profile-stage-percent {
  font-size: 2rem;
  color: black;
}

.invited-profile-search,
.invited-profile-skill-title {
  color: #828282;
}

.invited-profile-stage-text {
  color: #272727;
}

.invited-profile-stage-text-container {
  line-height: 1.1rem;
}

.span-see-detail-modal {
  color: #41479b;
}

.invited-profile-stages-progress {
  width: 80%;
  min-width: 6rem;

  @media screen and (max-width: 847px) {
    width: 60%;
  }
}

.points-text {
  margin-left: 1.563rem;
}

.section-container {
  margin-left: 1rem;
  width: 100%;

  @media screen and (max-width: 847px) {
    margin-left: 1.25rem;
  }
}

.technology-text-value {
  color: #5f5f5f;
}

.container-name-mw {
  max-width: 70%;
}

.button-detail-mw {
  max-width: 30%;
}

.loader-classname {
  width: 5rem !important;
  height: 1rem !important;
  margin: auto;
  margin-left: 0.625rem;
  margin-top: 1.125rem;
}

.circle-no-data {
  width: 9rem;
  height: 9rem;
  border: 5px solid #bcbcbc;
}

.loader-report {
  height: 0.5rem;
}

.report-btn-disabled-text {
  color: #959595;
}
.report-btn-disabled-button {
  border: 1px solid #959595;
}

.no-metrics-icon {
  display: flex;
  align-items: center;
  line-height: 8px;
}
