.first-filter-form-container {
  max-width: 35.313rem;
  min-height: 66vh;
  &.last-steps {
    min-height: 0;
  }
}

.border-unselected-types {
  border-color: #a0a0a0;
}

.first-filter-form-types-dropdown {
  margin-top: 0.65rem !important;
  border-radius: 8px;
  border-color: #4987be !important;
  .screening-completion-dot {
    display: none;
  }
  & div:hover {
    background-color: #fafafa;
  }
  li.dropdown-selec-option {
    padding-left: 0.25rem;
    font-weight: 600 !important;
    font-style: normal !important;
    color: #5e5e5e;
    opacity: 0.5;
    width: 100%;
  }
}
