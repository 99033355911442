@import '../../variables.scss';

.div-input {
  width: 20rem;
  height: 2rem;
}
.max-char-input {
  line-height: 1.25rem;
  //remove all default styles
  border: none;
  outline: none;
  background-color: transparent;
}
.max-char-input:focus-visible {
  outline: 0px !important;
}

.weight-input {
  width: 2.8rem;
}

.min-height-ia-text {
  min-height: 2.25rem;
}

//Chrome:
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//Firefox:
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: number-input;
}

.imange-ai {
  width: 3rem;
  height: auto;
}

.ai-image-container {
  svg {
    width: 2.2rem;
    height: auto;
  }
}

.svgs-text-ai {
  svg {
    color: $blue-principal !important;
    background-color: $blue-principal;
  }
}
