* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar {
  top: 90;
  left: -13.5rem;
  height: 100%;
  width: 13rem;
  background-color: #011528;
  transition: all 0.5s;
  z-index: 8;
}

.sidebar .nav-links {
  padding-top: 0.975rem;
}

.sidebar-scrollable {
  height: calc(100% - 50px);
  /* Adjust the height as needed */
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4987be transparent;
}

.sidebar-scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.sidebar-scrollable::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-scrollable::-webkit-scrollbar-thumb {
  background-color: #4987be !important;
  border-radius: 20px;
  border: 2px solid #ffffff80;
}

.sidebar .nav-links li {
  list-style: none;
}

.sidebar .nav-links li:nth-child(1) {
  list-style: none;
  margin: 0;
}

.sidebar-icon-rotate {
  transform: rotate(-90deg);
  transition: transform 0.5s;
}

.sidebar-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.sidebar .nav-links li img {
  margin-left: 1.1rem;
}

.sidebar .nav-links li a {
  text-decoration: none;
  white-space: nowrap;
}

.nav-links li a:hover {
  text-decoration: none;
  color: #fff;
}

.sidebar .nav-links li a span {
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 1.375rem;
  transition: 0.5s;
}

.menu-collapses {
  padding: 0 1.438rem;
}

.sidebar-active::after {
  content: '';
  background-color: #4987be;
  position: absolute;
  left: 0;
  top: 0;
  width: 0.375rem;
  height: 2.5rem;
}

.sidebar.close-sidebar {
  width: 4rem;
}

.sidebar.close-sidebar .sidebar-icon-rotate {
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.sidebar.close-sidebar .nav-links li .nav-links__item .link_name {
  color: rgba(255, 255, 255, 0.2);
  transition: 0.5s;
}

.sidebar.close-sidebar .nav-links li a {
  margin-left: 0.015rem;
  white-space: nowrap;
}

.sidebar.close-sidebar .menu-collapses {
  padding: 0 auto;
}

.home-section {
  position: relative;
  left: 0;
  transition: all 0.5s;
}

.home-content {
  width: 100%;
  height: inherit;
}

.sidebar.close-sidebar ~ .home-section {
  left: 0;
  transition: all 0.5s;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 2.188rem;
}

.home-section .home-content .bx-menu {
  margin: 0 0.938rem;
  cursor: pointer;
}

.home-section .home-content .text {
  font-weight: 600;
}

.icon-selected {
  stroke: #4987be;
  fill: #4987be;
}

.icon-normal {
  stroke: white;
  fill: white;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .sidebar.close-sidebar {
    width: 0;
  }

  .sidebar.close-sidebar ~ .home-section {
    width: 100%;
    left: 0;
  }

  .home-section {
    left: 0;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .sidebar {
    display: block;
    left: 0;
  }

  .home-section {
    left: 13.5rem;
    width: calc(100% - 13.5rem);
  }

  .sidebar.close-sidebar ~ .home-section {
    left: 4rem;
    width: calc(100% - 4rem);
  }
}

.ml-1_2rem {
  margin-left: 1.2rem !important;
}

/* burger menu to x */

.burger-menu {
  display: inline-block;
  height: 0.125rem;
  margin-right: 1.685rem;
  transform: translateX(0px);

  span.main-nav-toggle {
    display: block;
    width: 1.375rem;
    height: 100%;
    left: 1.065rem;
    position: absolute;
    top: 0.935rem;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 0;
      border-bottom: 4px solid #bbb;
      width: 100%;
      left: 0;
      right: 0;
      transition: all ease-out 0.4s;
    }

    &:after {
      top: 100%;
    }

    i {
      display: block;
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;
      height: 0.25rem;
      background-color: #bbb;
      width: 100%;
      position: absolute;
      top: 50%;
      transition: all ease-out 0.1s;
    }

    &.active-menu {
      &:after {
        transform: rotate(-45deg);
        transform-origin: center;
        top: 50%;
      }

      &:before {
        transform: rotate(45deg);
        transform-origin: center;
        top: 50%;
      }

      i {
        opacity: 0;
      }
    }
  }
}
