.profile-index-week-tag {
  background-color: #dddddd;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.filter-list-height {
  max-height: calc(100% - 80px);
}

.black-filter {
  background-color: rgba(51, 51, 51, 0.4);
}

.results-button {
  background-color: #e4e5eb;
  border-radius: 16px;
  outline: none;
}

.profiles-filters-tour-box-container {
  position: sticky;
  width: 100%;
  top: 5rem;
  z-index: -1;
}

.profiles-filters-tour-box {
  position: absolute;
  width: 100%;
  height: calc(100vh - 5rem);
  top: 0;
}

.header-not-approve-account {
  background: #DDE4E8;
  box-shadow: 0rem 0rem 0.188rem 0.188rem #52c1f870;
}

.empty-candidates-container {
  height: 16rem;
  width: 100%;
  border-radius: 8px;
}

.profiles-breadcrumb-container {
  height: 3.5rem;
  .description-container {
    max-width: none;
  }
}