.container-additional-questions {
  height: 90rem;
}

@media (min-width: 1200px) {
  .container-additional-questions {
    height: 30rem;
  }
}

@media (min-width: 1500px) {
  .container-additional-questions {
    height: 40rem;
  }
}

@media (min-width: 1800px) {
  .container-additional-questions {
    height: 52rem;
  }
}
