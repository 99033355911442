.modal-edit-workspace {
  margin: auto 30rem !important;
}

.input-name-workspace {
  background: white;
  max-width: 15.5rem;
  border-radius: 8px;
  border: 1.5px solid #828282;
}

.custom-icon-preview {
  height: 2rem;
  width: 2rem;
  border: 0.125rem solid;
  border-radius: 0.25rem;
  text-align: center;
  margin: 0 auto;
  img {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
}
