.created-workspace-title {
  color: #3d8c6b;
  font-size: 1.125rem;
}

.created-workspace-subtitle {
  color: #212529;
  font-size: 1.25rem;
}

.created-workspace-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 2rem;
  width: 90%;
}
