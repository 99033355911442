.personalize-button {
  color: #757575;
  background-color: transparent;
  border: none;
}

.personalize-title {
  font-size: 1rem;
  text-align: start;
  padding-bottom: 1.5rem;
}

.personalization-modal-w {
  max-width: 35rem;
}

.personalization-modal-h {
  max-height: 80vh;
  overflow-y: auto;
  @media screen and (max-height: 800px) {
    max-height: 65vh;
  }
}
