.complete-profile-button:disabled {
  color: rgba(16, 16, 16, 0.3);
  border-color: rgba(118, 118, 118, 0.3);
  cursor: not-allowed;
}

.rounded-bottom {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.custom-border-end {
  border: none !important;
  border-right: 1px solid #ccc !important;

  &:last-child {
    border-right: none !important;
  }

  @media screen and (max-width: 1300px) {
    border-bottom: 1px solid #ccc !important;
  }
}
