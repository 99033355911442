@import '../../variables.scss';
.form-container {
  height: 2.25rem;
  &-input {
    background: white;
    border-radius: 8px;
    border: 1.5px solid #828282;
    input {
      outline: none;
      font-weight: 500;
      line-height: 0.938rem;
      align-items: center;
      letter-spacing: 0.4px;
    }
  }
}
.selected-border {
  border-color: $blue-principal !important;
  transition: 0.2s;
}
