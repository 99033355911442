.layout-container {
  min-width: 25.188rem;
  width: 100%;
  display: flex;
  flex: 0 1 calc(50% - 0.625rem);
  flex-direction: column;
  margin: auto;

  @media screen and (min-width: 990px) {
    min-width: 19rem;
  }
}

@media screen and (max-width: 1350px) {
  .layout-container {
    width: 100% !important;
    max-width: 40.625rem !important;
  }
}

.layout-fixed-height {
  height: 21.875rem;
}

.layout-title-container {
  background: #b7e4fb;
  padding: 0.938rem;
  border-radius: 5px 5px 0px 0px;
}

.layout-title-container-text {
  color: #4987be;
}
