.favorites-page-container {
  max-width: 65rem;
}

.favorite-list-item-name-container {
  min-width: 10rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.favorite-list-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboard-name-link:hover {
  text-decoration: underline;
}

.profiles-list-container[data-size~='0'] {
  .favorites-list-name-width {
    width: 100%;
  }

  .favorites-list-actions-width {
    width: 4.5rem;
  }

  .favorites-list-english-width {
    width: 2.75rem;
  }

  .favorites-list-score-width {
    width: 3.25rem;
  }

  .favorites-list-country-width {
    width: 2rem;
  }

  .favorites-list-tech-width {
    width: 4.9rem;
  }
}

.profiles-list-container[data-size~='450'] .favorites-list-name-width {
  width: 25%;
}

.profiles-list-container[data-size~='700'] {
  .favorites-list-country-width {
    width: 5rem;
  }

  .favorites-list-tech-width {
    width: 8.25rem;
  }

  .favorites-list-score-width {
    width: 6.1rem;
  }
}

.profiles-list-container[data-size~='800'] .favorites-list-english-width {
  width: 8.25rem;
}

.profiles-list-container[data-size~='900'] .favorites-list-english-width {
  width: 10.5rem;
}

.empty-space-table-title {
  min-width: 10rem;
}

.favorites-list-title {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #272727;
}

.favorites-list-button {
  background: #4987be;
  border-radius: 1rem;
}

.favorites-list-button-text {
  font-size: 0.875rem;
  line-height: 1rem;
}

.favorites-list-titles,
.favorites-list-skill-name {
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.1rem;
  color: #424242;
}

.favorites-list-item-container {
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  min-height: 3.5rem;
  line-height: 1rem;
  color: #272727;
}

.favorites-list-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 40%;
}

.favorites-list-item-name-min-width {
  min-width: 7rem;
}

.favorites-list-item-percent {
  height: 1.375rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.07rem;
  border-radius: 20px;
  padding: 0 0.7rem;
  flex-shrink: 0;
  isolation: isolate;
}

.favorites-list-item-percent-subitem {
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 20px;
  position: absolute;
}

.favorites-list-item-percent[data-color='yellow'] {
  background-color: #ffdc57;
}

.favorites-list-item-percent[data-color='blue'] {
  background-color: #4987be;
}

.favorites-list-item-percent[data-color='green'] {
  background-color: #5edca7;
}

.dashboard-name-link {
  color: #272727;
}

.dashboard-name-link:hover {
  color: #272727;
  text-decoration: underline;
  transition: 0.5s;
}

.pill-bg {
  background: #e5e6e9;
  border-radius: 20px;
  width: 2.875rem;
  height: 1.375rem;
}
