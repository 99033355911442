@import '../../variables.scss';
.custom-date-button {
  width: 9.375rem;
  height: 2.375rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.063rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(239, 240, 244, 1);
  color: rgba(24, 44, 76, 1);
}
.container-picker {
  background: #ffffff !important;
  border: 1px solid $blue-principal !important;
  border-radius: 8px !important;
}
.react-datepicker__day-names {
  margin-top: 0.625rem;
  background: #ffffff !important;
}
.react-datepicker__day-name {
  font-family: 'Inter', sans-serif;
  font-size: 0.75rem;
  margin: 0rem 0.3rem;
  color: #828282;
}
.react-datepicker__header {
  background: #ffffff;
  border: 0rem;
}
.react-datepicker__navigation-icon::before {
  margin-top: 1rem;
  border-color: $blue-principal;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
  display: none;
}
.react-datepicker__current-month {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
}
.react-datepicker__month-container {
  padding: 0.6rem 0.8rem;
}
.react-datepicker__day {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0.3rem;
  width: 2rem;
  height: 2rem;
}
.react-datepicker__week {
  padding: 0.1rem 0.3rem;
}
.react-datepicker__day--selected {
  background: $blue-principal;
  border-radius: 6.625rem;
}
.react-datepicker__day--selected:hover {
  background: $blue-principal;
  border-radius: 6.625rem;
  transform: scale(1.2);
}
.react-datepicker__day:hover {
  border-radius: 6.625rem;
  background: $blue-principal;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 6.625rem;
  background: $blue-principal;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
  display: none;
}
.react-datepicker-popper {
  z-index: 2;
  width: fit-content;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day:hover {
  color: white;
}
