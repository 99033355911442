.component-button-filters {
  border-radius: 8px;
  border: 1px solid #787878;
}

.component-button-filters-selected {
  border: 1px solid #4987be;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
}

.component-text-filters {
  color: #828282;
  font-weight: 700;
  font-size: 0.875rem;
}

.component-text-filters-selected {
  font-size: 0.875rem;
  color: #182c4c;
  font-weight: 700;
}
