.member-row-container {
  padding: 0.75rem 0;
  border-bottom: 0.5px solid #757575;
}

.member-row-image {
  width: 2rem;
  height: 2rem;
}

.member-row-text {
  line-height: 1;
}

.member-without-account-text {
  color: #757575;
}

.delete-workspace-members {
  background: transparent;
  border: none;
}
