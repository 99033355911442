.diamond-icon-tall {
}

.diamond-background {
  padding: 0.25rem;
  border: 2px solid rgba($color: #e5c87e, $alpha: 1);
  background-color: white;
  transition: 0.2s;
  &:hover {
    background-color: rgba($color: #fffcef, $alpha: 1);
    transition: 0.2s;
  }
}
