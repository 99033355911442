@import '../../variables.scss';

.btn-close-search:disabled {
  color: #828282;
  border: 1px solid #828282;
  cursor: not-allowed;
}

.finish-search-button {
  background-color: #ffe2e3;
  color: #fd6466;
  letter-spacing: 0.07rem;
}

.search-dashboard-search-title-color {
  color: #4f4f4f;
}

.batch-action-button {
  width: 7.5rem;
  height: 2rem;
  background: none;
}

.button-search-config-qualitative {
  color: #757575;
  background: none;
  border: none;
  font-weight: 600;
}

.score-filter-slider {
  width: 10rem;
}

.bg-info-subtle {
  background-color: #4987be !important;
}

.batch-state-select-button {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #757272;
}

.mr-6 {
  margin-right: 4rem;
}

.focus-invitation-animation {
  box-sizing: border-box;
  animation-name: pulseBorder;
  animation-duration: 1s;
}

.min-height {
  min-height: 3.75rem;
}

@keyframes pulseBorder {
  0% {
    border-color: #4987be;
  }

  50% {
    border-color: #127e5f;
  }

  100% {
    border-color: #4987be;
  }
}

.vertical-line {
  border-left: 2px solid #d9d9d9;
  height: 4.375rem;
}

@media (max-width: 990px) and (min-width: 768px) {
  .w-container-indicators {
    width: 70%;
  }
}

.container-search-dashboard {
  max-width: 70rem;
}

.container-info {
  width: 85%;
}

.btn-close-search {
  border: 1px solid #dc251c;
  color: #dc251c;
}

.input-search-dashboard {
  font-weight: 500;
  line-height: 0.938rem;
  align-items: center;
  letter-spacing: 0.4px;
}

.input-search-dashboard::placeholder {
  font-style: italic;
  font-weight: 400;
  color: #adadad;
}

.border-input-search {
  border: 1.5px solid #828282 !important;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.loader-download-csv {
  right: 0.75rem;
  height: 0.5rem;
  width: 0.5rem;
}

.lds-ellipsis div {
  top: 0rem;
  width: 0.5rem;
  height: 0.5rem;
}

@media (max-width: 1600px) {
  .container-search-dashboard {
    max-width: 65rem;
  }
}

@media (max-width: 1200px) {
  .container-search-dashboard {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .container-search-dashboard {
    width: 100%;
  }
}

.search-date-input {
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 0.5rem !important;
  margin: 0 0.438rem !important;
  color: #182c4c;
  box-shadow: none;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
}

.search-hour-input {
  height: 2.375rem;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
}

.search-date-input:disabled {
  cursor: not-allowed;
}

.search-input-gray-text {
  color: gray !important;
}

.react-datepicker-wrapper {
  width: fit-content;
}

.react-datepicker__tab-loop {
  display: inline-block !important;
}

.close-search-modal-confirmation-button {
  width: fit-content;
  padding: 0.8rem 1rem;
  border: 0;
  border-radius: 1rem;
}

.close-search-modal-close-text {
  color: #4987be;
}

.close-search-modal-container {
  height: 16rem;
  width: 45rem !important;
  padding: 1.8rem;
  border-radius: 1rem;
}

.name-indication-min-width {
  min-width: 25%;
}

.icon-max-width {
  max-width: 1.25rem;
}

.indicator-with-order {
  max-width: 90%;
}

.w-fit-content {
  width: fit-content !important;
}

.button-default {
  border: 0;
}

.total-invitations-text {
  color: #828282;
}

.charts-section-container {
  justify-content: space-between;

  @media screen and (max-width: 893px) {
    justify-content: center;
  }

  @media screen and (min-width: 990px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 1350px) {
    flex-direction: column-reverse !important;
  }
}

.total-invitations-number {
  font-size: 2rem;
  margin-bottom: 0.125rem;
}

.labels-container {
  margin-right: 2.9rem;
}

.h-emails-error-container {
  max-height: 17.8rem;
}

.h-emails-error-container::-webkit-scrollbar {
  width: 0.375rem;
}

.h-emails-error-container::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 15px;
}

.h-emails-error-container::-webkit-scrollbar-thumb {
  background: #4987be;
  border-radius: 15px;
}

.save-icon-size {
  height: 0.75rem;
  width: 0.75rem;
}

.chart-column-container {
  flex: 0 1 calc(50% - 0.625rem);
}

@media screen and (max-width: 1350px) {
  .chart-column-container {
    width: 100%;
    flex: auto !important;
    margin-bottom: 3.125rem;
  }
}

.score-dropdown-width {
  width: 5rem;
  color: #303030;
}

.filter-in-table-btn {
  border: 0;
  background: transparent;
  border-bottom: 2px solid $blue-principal;
}

.apply-a-filter-text {
  font-style: italic !important;
}

.pill-button {
  padding: 0.375rem 1rem;
  border: 0;
  height: 2rem;
}

.country-flag-img {
  width: auto;
  height: 2.3rem;
}

.country-flag-large-img {
  width: auto;
  height: 2.3rem;
}

.province-stats-detail-modal-container {
  width: 29rem !important;
  border-radius: 1rem;
}

.text-xxl {
  font-size: 1.625rem;
}

.border-bottom-divisor {
  border-bottom: 0.5px solid #828282;
}

.chart-provinces-container {
  max-height: 18.75rem;
}

.chart-country-province-name-text {
  color: #303030;
}

.chart-country-province-count-text {
  color: #5e5e5e;
}

.chart-country-single-container {
  max-width: 20rem;
}

.chart-location-items-container {
  max-height: 22.5rem;
}

.chart-country-item {
  width: 10rem;
}

.chart-country-item-divisor {
  border-bottom: 2px solid #d9d9d9;
  padding: 0.938rem;
}

.chart-country-item-province-detail-btn {
  color: #828282;
  text-decoration: underline;
  border: 0;
}

.filter-tab-button {
  box-sizing: content-box !important;
  position: relative;
  width: 100%;
  line-height: 0;
  padding: 1rem 0.5rem;
  border: 1px solid #bcbcbc;
  border-radius: 1rem 1rem 0rem 0rem;

  &::before {
    width: 100%;
    height: 0;
    content: '';
    position: absolute;
    bottom: 0;
    background-color: transparent;
  }

  span {
    font-size: 1.5rem;
    color: #757575;
  }

  &.selected-tab-button {
    color: #4987be;
    border-color: #4987be;

    &::before {
      height: 0.25rem;
      background-color: #4987be;
    }

    span {
      color: #4987be;
    }
  }
}

.total-invitations-quantity {
  font-size: 2rem;
  line-height: 2rem;
}

.chart-country-item-province-container {
  width: 8rem;
}

.chart-country-item-province-text {
  text-align: end;
}

.allow-certificate-download-modal-width {
  width: 30.75rem !important;
}
