.style-input-portfolio {
  border: 1px solid #eff0f4;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.portfolio-container-custom {
  min-width: 8.125rem;
  max-width: 8.125rem;
}

.portfolio-margin-img-custom {
  width: 1.125rem;
  height: 1.0625;
  margin-left: 0.625rem;
}

.portfolio-margin-span-custom {
  margin-left: 1.375rem;
}

.portfolio-gap-custom {
  gap: 0px 0.3125rem;
}
