.option-multiple-container {
  font-size: 1rem;
  font-weight: 400;
  color: #686868;

  p {
    margin: 0;
  }
}

.maya-blue-solid-border {
  border: 0.065rem solid rgba(26, 172, 240, 1);
  border-radius: 8px;
}

.correct-option {
  background-color: #d5f6e8;
  border: 1px solid #5edca7;
}

.partial-correct-option {
  background-color: #fff4cc;
  border: 1px solid #806600;
}

.incorrect-option {
  background-color: #f9d4d2;
  border: 1px solid #dc251c;
}

.cero-option {
  background-color: #e6e6e6;
  border: 1px solid #607d8b;
}

.w-90 {
  width: 90%;
}

.option-icon-w {
  width: 2rem;
}

.option-score-w {
  width: 5rem;
}

.modal-container-check-result-custom-height {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 3.9rem;
}

.modal-container-check-border-color-red {
  border: 1px solid #f44336;
}
.modal-container-check-border-color-green {
  border: 1px solid #2b9f5a;
}
.modal-container-check-border-color {
  border: 1px solid rgba(88, 193, 245, 0.58);
}

.modal-container-check-border-color-yellow {
  border: 1px solid #806600;
}

.modal-container-check-border-color-gray {
  border: 1px solid #607d8b;
}

.circle-blue-rounded {
  min-width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #4987be;
}

.circle-blue-bg-selected {
  width: 0.625rem;
  height: 0.625rem;
  background-color: #4987be;
}

.option-formula-position-format {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  z-index: 2;
  box-sizing: border-box;
}

.option-formula-background {
  background-color: rgba(255, 255, 255, 0.46);
}

.bg-grey {
  background-color: #ececec;
}

.default-styles-none {
  border: none;
}

.gap {
  gap: 0.5rem;
}

.rounded {
  border-radius: 0.25rem;
}
