.background-top {
  left: 0;
  top: 0;
}

.banner-links-container {
  a:hover {
    text-decoration: none;
    color: #6c757d;
  }
}
