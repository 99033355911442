.profile-list-item-name-container {
  min-width: 10rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-list-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboard-name-link:hover {
  text-decoration: underline;
}

.profiles-list-container[data-size~='0'] {
  .profiles-list-name-width {
    width: 100%;
  }

  .profiles-list-actions-width {
    width: 4.5rem;
  }

  .profiles-list-english-width {
    width: 2.75rem;
  }

  .profiles-list-sent-date-width {
    width: 2.75rem;
  }

  .profiles-list-score-width {
    width: 3.25rem;
  }

  .profiles-list-country-width {
    width: 2rem;
  }

  .profiles-list-tech-width {
    width: 4.9rem;
  }
}

.profiles-list-container[data-size~='450'] .profiles-list-name-width {
  width: 25%;
}

.profiles-list-container[data-size~='700'] {
  .profiles-list-country-width {
    width: 5rem;
  }

  .profiles-list-invited-width {
    width: 7rem;
  }

  .profiles-list-tech-width {
    width: 8.25rem;
  }

  .profiles-list-score-width {
    width: 6.1rem;
  }
}

.profiles-list-container[data-size~='800'] .profiles-list-english-width {
  width: 8.25rem;
}

.profiles-list-container[data-size~='900'] .profiles-list-english-width {
  width: 10.5rem;
}

.profiles-list-container[data-size~='800'] .profiles-list-sent-date-width {
  width: 8.25rem;
}

.profiles-list-container[data-size~='900'] .profiles-list-sent-date-width {
  width: 10.5rem;
}

@for $i from 0 through 75 {
  $percentage: $i * 1.3%;
  .mix-yellow-#{$i} {
    background-color: mix(#4987be, #ffdc57, $percentage);
  }
}

@for $i from 0 through 25 {
  $percentage: $i * 4%;
  .mix-green-#{$i} {
    background-color: mix(#5edca7, #4987be, $percentage);
  }
}
