.dropdown-lang-select-container {
  font-size: 0.874rem;
  line-height: 1rem;
  color: #828282;
  background-color: #fff;
  z-index: 1;
}

.dropdown-lang-select-arrow {
  transition: transform 0.3s;
}


.dropdown-lang-select-arrow-open {
  transform: rotate(-180deg);
}

.dropdown-lang-select-options-container {
  list-style: none;
  position: relative;
  max-height: 0;
  min-width: 100%;
}

.dropdown-lang-select-options-container-open {
  max-height: 12.5rem;
}

.dropdown-lang-select-option {
  white-space: nowrap;
}

.dropdown-lang-select-option:hover {
  background-color: #fafafa;
}

.dropdown-lang-selected-option {
  color: #505050;
  background-color: #ebebeb;
}

.dropdown-lang-select-placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.125rem;
}

.dropdown-lang-max-height {
  max-height: 3.125rem;
}

.dropdown-lang-container-index {
  z-index: 3;
}

.dropdown-lang-gray-bg {
  background-color: #f4f4f4;
}


.notification-container{
  position: relative;
  left: -40px;
}

.without-decoration {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit; 
  }
}

.notification_badge {
  
    font-weight: bold;
    color: white;
    padding: 2px 0;
    font-size: 12px;
    position: absolute;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: red;
    text-align: center;
    top: 0;
    left: 0;
}