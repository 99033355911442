.steps-children-container::-webkit-scrollbar {
  display: none;
}

.disabled-button-background {
  background-color: #757575;
}

.login-form-container {
  width: 90%;
}

.login-form-button {
  padding: 0.65rem 0.75rem;
}

.login-step-subtitle {
  font-size: 1.375rem;
}

.login-section-divider {
  padding: 2vh 0;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 2.25rem;
  letter-spacing: 0.065rem;
}

.login-section-divider::before {
  content: '';
  margin-right: 1rem;
  top: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}

.login-section-divider::after {
  content: '';
  margin-left: 1rem;
  top: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}

.modal-tac-container {
  width: 100% !important;
  max-width: 44rem !important;
}

.modal-close-icon {
  font-size: 3.125rem !important;
}

.close-modal-button {
  width: fit-content;
}

.register-with-google-btn {
  color: #757575;
  border: 0.063rem solid #757575;
}
