.Navbar-container {
  &-input {
    background: white;
    border-radius: 8px;
    border: 1.5px solid #828282;

    input {
      outline: none;
      font-weight: 500;
      line-height: 0.938rem;
      align-items: center;
      letter-spacing: 0.4px;
    }
  }

  &-options {
    &-alkymers {
      strong {
        font-size: 0.875rem;
      }
    }

    &-relevant {
      font-size: 0.875rem;

      span {
        color: gray;
      }
    }

    &-filter {
      display: inline-block;
    }

    .options-button {
      outline: none;
      background: transparent;
    }
  }
}

.input-search {
  width: 85%;
}

.input-search::placeholder {
  font-style: italic;
  font-weight: 400;
  color: #adadad;
}

.link-router:hover {
  color: #0056b3;
  text-decoration: underline;
}