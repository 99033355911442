.number-input-container {
  width: 3.5rem;

  .number-input-arrows-container {
    top: 0.188rem;
  }

  .number-input-arrow {
    height: 0.5rem;
  }
}
