@import '../../../variables.scss';

.input-profile-custom {
  background-color: #ffffffd0 !important;
  border-radius: 5rem !important;
  .ant-input {
    color: $blue-principal !important;
  }
  svg {
    color: $blue-principal !important;
  }

  span {
    background-color: transparent !important;
    border: none !important;
    &:focus {
      border: none;
    }
  }
  input,
  span {
    background-color: transparent !important;
    border: none !important;
    &:focus {
      border: none;
    }
  }
}

.profile-border-radius {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.invitations-and-challenges-container {
  overflow-x: scroll;
}

.invitation-item-container {
  min-width: 10rem;
  max-width: 10rem;
  height: 5rem;
  background-color: #ffffff3b;
  border: #ffffff 0.0625rem solid;
  border-radius: 0.5rem;
  color: white;
  text-align: center;

  &.active {
    background: #ffffff !important;
    color: $blue-principal !important;
    box-shadow: inset 0 0 0 0.1rem $blue-principal;

    .invitation-item-score {
      background: $blue-secondary !important;
      color: #ffffff !important;
      border: #ffffff 0.0625rem solid;
    }
  }
}

.invitation-empty-container {
  height: 5rem !important;
}

.invitation-item-score {
  position: absolute;
  top: -12%;
  right: -5%;
  background-color: #ffffff;
  color: $grey-secondary;
  border-radius: 5rem;
}

.custom-loader-position {
  height: 1rem;
}

.pending-credits {
  background-color: $grey-4;
  color: $grey-1;
}
