@import '../../../variables.scss';
a .second-step-container {
  input {
    color: $grey-1;
  }
}

.test-grid-container-create-screening {
  height: 58vh;
}

@media screen and (min-height: 1000px) {
  .test-grid-container-create-screening {
    height: 65vh;
  }
}

@media screen and (min-height: 1150px) {
  .test-grid-container-create-screening {
    height: 73vh;
  }
}

@media screen and (min-height: 1500px) {
  .test-grid-container-create-screening {
    height: 80vh;
  }
}

.test-grid-container-tests {
  height: 67vh;
}

@media screen and (min-height: 1000px) {
  .test-grid-container-tests {
    height: 73vh;
  }
}

@media screen and (min-height: 1150px) {
  .test-grid-container-tests {
    height: 81vh;
  }
}

@media screen and (min-height: 1500px) {
  .test-grid-container-tests {
    height: 87vh;
  }
}

.test-grid-container-roles {
  height: 58vh;
}

@media screen and (min-height: 1000px) {
  .test-grid-container-roles {
    height: 65vh;
  }
}

@media screen and (min-height: 1150px) {
  .test-grid-container-roles {
    height: 73vh;
  }
}

@media screen and (min-height: 1500px) {
  .test-grid-container-roles {
    height: 80vh;
  }
}

.full-row-width-text {
  grid-column: 1 / -1;
  margin: 0 !important;
  padding: 0;
}

.horizontal-loader-height {
  height: 1rem;
}

.horizontal-loader-container-create {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
}

.total-number-text {
  color: $blue-principal;
}

.select-color {
  color: $blue-dark;
}

.tests-bg {
  background-color: rgba(65, 71, 155, 0.05);
}

.tests-search-input-full,
.tests-search-input-empty {
  border: 0.125rem solid;
  transition: 0.2s;
}

.tests-search-input-full {
  border-color: $blue-principal;
  transition: 0.2s;
}

.tests-search-input-empty {
  border-color: #828282;
  transition: 0.2s;
}

.empty-state-container {
  width: 17.625rem;
  height: 17.625rem;
}

.test-button-filters {
  border-radius: 8px;
  color: $grey-1;
  border: none;
  gap: 0.3rem;
}

.test-button-filters-selected {
  border: none;
  color: $blue-dark !important;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  gap: 0.3rem;

  span {
    // color: $blue-dark !important;
    text-decoration: underline;
    text-decoration-color: $blue-dark;
  }
}

.test-text-filters {
  color: #828282;
  font-weight: 700;
  font-size: 0.875rem;
}

.container-test-role {
  background: rgba(82, 192, 247, 0.49);
  border-radius: 8px;
}

.text-test-role {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(48, 48, 48, 0.8);
}

.button-filter {
  display: inline-block;
  padding: 2rem 2rem;
  text-align: center;
  // width: 10rem;
}

.drops-filter {
  display: inline-block;
  text-align: center;
  position: none;
  flex-direction: row;
  width: 8rem;
}

.filters-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border: 1px solid $grey-4;
  border-radius: 0.2rem;
}

.filters-dropdown-container-toggle {
  cursor: pointer !important;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 29.26px;
  text-align: left;
  width: 100%;
  padding: 0;
  padding: 0rem 4rem;

  svg {
    color: $blue-dark;
  }
}

@media screen and (max-width: 768px) {
  .filters-dropdown-container-toggle {
    padding: 0rem 2rem;
  }
}

.filters-dropdown-content {
  position: absolute;
  background-color: white;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 0.5rem;
  top: 100%;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden;
  width: 100%;
  padding: 1rem;
}

.filters-dropdown-content.show {
  opacity: 1;
  transform: translateY(0);
  z-index: 100;
  visibility: visible;
}

.second-step-selected-tests-container {
  display: flex;
  gap: 1rem;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  padding-bottom: 0.3rem;

  &::-webkit-scrollbar {
    height: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue-dark;
  }

  .second-step-selected-tests-item {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0.3125rem;
    background: $grey-1;
    color: #ffffff;
    padding: 0.5rem 1rem;
    max-height: 2rem;
    width: 12rem;
    min-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
    justify-content: space-between;

    &:hover .item-icons {
      cursor: pointer;

      svg {
        display: flex !important;
      }
    }

    &:hover {
      padding-right: 0.2rem;
    }
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .item-icons {
    svg {
      display: none;
    }
  }
}

.selected-tests-container {
  background-color: $grey-3;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.tests-container-second-step {
  overflow-y: auto;
  margin: 1rem 0rem;

  &::-webkit-scrollbar {
    background-color: $grey-3;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid $blue-principal;
  }

  direction: rtl;

  & > * {
    direction: ltr;
  }
}

.testgroups-filter-container {
  background-color: $blue-light;
}

.modal-width-small {
  width: 10rem !important;
  height: 3rem !important;
}
