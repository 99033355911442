.custom-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
}

.panel-header {
  position: relative;
  justify-content: space-between;
  align-items: center;
  border: 'none';
}

.panel-container {
  margin-bottom: 1rem;
  border-bottom: none;
}

.ant-switch-handle {
  position: absolute;
  top: 4px;
  left: 5.5px;
  width: 15px;
  height: 15px;
  transition: all 0.2s ease-in-out;
}