@import '../../variables.scss';

.dropdown-workspace-select-container {
  font-size: 0.874rem;
  line-height: 1rem;
  color: #828282;
  z-index: 1;
  border: 2px solid #c4c4c499;
}

.width-button-open {
  width: 12rem;
}

.dropdown-workspace-select-arrow {
  transition: transform 0.3s;
  margin-left: auto;
}

.dropdown-workspace-select-arrow-open {
  transform: rotate(-180deg);
  transition: transform 0.3s;
  margin-left: auto;
}

.dropdown-workspace-select-options-container {
  list-style: none;
  max-height: 0;
  min-width: 100%;
}

.dropdown-workspace-select-options-container-open {
  width: 11rem;
  max-height: 20.5rem;
  overflow: initial !important;
  border: 2px solid #c4c4c499;
}

.ul-container {
  max-height: 12rem;
}

.dropdown-workspace-select-option {
  white-space: nowrap;
}

.star-empty {
  display: none;
}

.dropdown-workspace-select-option:hover {
  background-color: #e6e6e6;

  .star-empty {
    display: block;
  }
}

.dropdown-workspace-selected-option {
  color: #505050;
  background-color: #ebebeb;
}

.dropdown-workspace-select-placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.125rem;
}

.dropdown-workspace-max-height {
  max-height: 3.125rem;
  max-width: 12rem;
}

.dropdown-workspace-container-index {
  z-index: 10;
}

.footer-dropdown-workspace {
  border-top: 1px solid #bcbcbc;
}

.box-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  overflow: hidden;
}

.img-icon-dropdown {
  width: 1rem;
  height: 1rem;
  object-fit: cover;
}

.selected-border {
  border-color: $blue-principal !important;
  transition: 0.2s;
}

.unselected-border {
  border-color: #828282 !important;
  transition: 0.2s;
}

.modal-create-workspace {
  margin: auto 30rem !important;
  min-width: 30rem !important;
}

.input-name-workspace {
  background: white;
  max-width: 15.5rem;
  border-radius: 8px;
  border: 1.5px solid #828282;
}

.text-gray {
  color: #5d5d5d !important;
}
