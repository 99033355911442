.formation-border-increment {
  border-top: 1px solid rgba(130, 130, 130, 0.33);
}

.formation-collapsed-container {
  max-width: 85%;
}

.formation-collapsed-label-anchor {
  margin-top: -5px;
}

.formation-collapsed-container-width {
  width: 80% !important;
  margin-bottom: 0rem;
}

.formation-width-custom {
  width: 33%;
}

.formation-certificate-custom {
  position: absolute;
  right: 0;
}

@media (max-width: 600px) {
  .formation-collapsed-container-width {
    width: 100% !important;
    margin-bottom: 2rem;
  }
  .formation-certificate-custom {
    bottom: 0;
    left: 50%;
    transform: translateX(-40%);
  }
}
