@import '../../variables.scss';

.div-input-write-short {
  width: 70%;
}
.div-input-write-typing {
  width: 90%;
}
.max-char-input {
  color: $blue-principal;
  line-height: 1.25rem;
}
.max-char-input:focus-visible {
  outline: 0px !important;
}

.weight-input {
  width: 2.8rem;
}
