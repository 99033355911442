.screening-modal {
  max-width: 65rem;
  .ant-modal-footer {
    border-top: 0.2rem solid #4987be;
  }
  .ant-modal-header {
    background-color: #4987be;
    div {
      color: white;
    }
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}
