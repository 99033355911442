@import '../variables.scss';

.user-heading {
  max-width: none;
  background: #ffffff;
}

.user-heading-img {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  border: 0.5px solid #a8a8a8;
}

.hover-overlay {
  border-left: 5px solid #f8f7f5;
}

.hover-overlay:hover {
  background: #f8f7f5;
  border-left: 5px solid #4987be;
}

.selected {
  background: #f8f7f5;
  border-left: 5px solid #4987be;
}

.text-card-width {
  font-size: 1.375rem;
  line-height: 1.688rem;
  text-align: center;
  color: #272727;
}

.email-card-width {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #787878;
  word-break: normal;
  word-wrap: normal;
}

.card-button-edit {
  padding: 0.5rem;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 4px;
}

.card-button-text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
  margin: 0rem 0.25rem;
}

.button-edit-image {
  margin-left: 0.625rem;
  background-color: transparent;
  border: none;
}

.button-edit-image-profile {
  right: 1.25rem;
  bottom: 0;
}

.icon-edit-image {
  font-size: 1.5rem;
}

.video-upload-text {
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #272727;
}

.bubble {
  background: #eff0f4;
  border-radius: 0.935rem;
  width: 3.5rem;
  height: 1.875rem;
}

.profile-menu-container {
  padding: 4rem;
}

.player-size-profile {
  width: 17.375rem !important;
}

.input-picker-color-custom {
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  background-color: #fff;
  border-color: black;
  color: black;
  border: none;
  border-radius: 0.25rem;
}

.input-picker-color-custom[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

.input-picker-color-custom[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

.button-edit-image-profile {
  width: 1.875rem;
}

.image-upload > input {
  display: none;
}

.list-partners-company {
  list-style-type: none;
}

.css-1wa3eu0-placeholder {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
}

.w-33 {
  width: 33%;
}

.w-66 {
  width: 66%;
}

.button-save-profile {
  background: $blue-principal;
}

.button-save-profile:disabled {
  background-color: #828282;
}

.input-type-color-profile {
  border: 1px solid rgba(115, 115, 115, 1);
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.1);
  width: 137px;
  height: 40px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 8px;
}

.profile-personalization-img {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}

.container-inputs-profile {
  width: 33%;
}

@media (max-width: 600px) {
  .player-size-profile {
    width: 100% !important;
  }
  .email-card-width {
    word-break: break-word;
    word-wrap: break-word;
  }
}

@media (max-width: 550px) {
  .input-type-color-profile {
    width: 25%;
  }
  .container-inputs-profile {
    width: 50%;
  }
}

@media (max-width: 440px) {
  .input-type-color-profile {
    width: 30%;
  }
}

@media (max-width: 380px) {
  .input-type-color-profile {
    width: 35%;
  }
}

@media screen and (min-width: 992px) {
  .user-heading {
    max-width: 22.815rem;
  }
}

.button-image {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.button-image:disabled {
  cursor: not-allowed;
}
