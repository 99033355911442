.saved-filters-list li::before {
  content: '•';
  font-size: 1rem;
  margin-right: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .search-container {
    max-width: 49%;
  }
}
