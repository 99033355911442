@import '../../variables.scss';

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-evaluation-summary-nav {
  @include flex-between;
  margin-bottom: 1rem;
  background-color: $grey-3;
  padding: 0.5rem 1rem;
  border-radius: 0.3125rem;

  p {
    margin: 0;
  }
  .summary-content {
    @include flex-between;
    width: 100%;
  }

  .summary-items {
    @include flex-between;
    gap: 1.875rem;
  }

  .summary-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .button-save-and-exit {
    color: $blue-dark;
    text-decoration: underline;
    border: none;
    background-color: $grey-4;
  }

  .button-save-and-exit.disabled {
    color: $grey-1 !important;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.space-title-evaluation {
  letter-spacing: 1px;
}
