.scroll-to-top-button {
  border-radius: 50%;
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  z-index: 10;
  bottom: 1rem;
  right: 1rem;
  background-color: #4987be;
}

.scroll-to-top-button:disabled {
  background-color: #9fd7f3;
}
