.rec-dot {
  background-color: #bae2f6 !important;
  box-shadow: none !important;
  width: 15px !important;
  height: 15px !important;
}
.rec-dot_active {
  background-color: #4987be !important;
  box-shadow: none !important;
}
.rec-arrow {
}

.dkEaAU {
  background-color: #ddeef7 !important;
}
