#root {
  background-color: #f4f4f4;
  font-family: 'Mulish', sans-serif;
  min-height: 100vh;
}

a:focus {
  box-shadow: none !important;
}

.switch-reset {
  padding-left: 0;
}

.custom-control {
  padding-left: 0;
}

.no-underline-hover:hover {
  text-decoration: none !important;
}

.badge-filter {
  cursor: pointer;
}

.container-max {
  max-width: 1400px;
  margin: auto;
}

.main-container {
  min-height: 80vh;
}

.min-h-100 {
  min-height: 100%;
}

.btn-primary {
  background-color: #4987be !important;
  border-color: #4987be !important;
}

.hidden {
  opacity: 0;
  transform: translateX(-20px);
}

.show {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 1s, transform 1s;
}

.outline-none:focus,
.outline-none:focus-visible,
.outline-none:focus-within {
  outline: none;
}

.show {
  transition: opacity 0.5s !important;
}

.non-selectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav-link.active {
  background: none !important;
  border-bottom-color: #f8f9fa !important;
}
