.invited-profile-comments-container-title {
  font-size: 1.125rem;
}

.invited-profile-add-comment-button,
.invited-profile-delete-comment-button {
  background: none;
  border: none;
}

.invited-profile-comment-title-data {
  color: #000000;
}
.invited-profile-comment-text {
  word-break: break-all;
  color: #5e5e5e;
}
.notes-empty-state-button {
  padding: 0.5rem 1.75rem;
  margin: auto;
}

.border-button {
  border: 1px solid #4987be;
}

.comment-input-height {
  min-height: 16.875rem;
  resize: none;
}

.notes-section {
  border: 1px solid #4987be;
}

.screening-profile-comment-modal {
  .ant-modal-body {
    padding-bottom: 0;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    border: none;
  }
}

.border-bottom-comment {
  border-bottom: 1px solid #82828254;
}
