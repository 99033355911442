.camera-image-container {
  width: 16.25rem;
  height: 9.375rem;
  border-radius: 0.5rem;
  object-fit: contain;
}

.slider-custom-styles {
  width: 13.125rem;
  .ant-slider-rail {
    background-color: #ebebeb;
  }
  .ant-slider-handle {
    background-color: #000000;
    opacity: 75%;
    border: 0px;
    border-radius: 50px !important;
    transform: translate(-50%, 0) !important;
  }
}
