.profiles-search-badge svg {
  color: rgba(255, 255, 255, 0.5);
}

.profiles-search-badge:hover svg {
  color: #fff;
}

.ant-slider-handle,
.ant-slider-handle-1,
.ant-slider-handle-2 {
  border-color: #4987be !important;
  padding: 8px;
  border-radius: 4px !important;
  transform: translate(-50%, -10%) !important;
}

.ant-slider-track,
.ant-slider-track-1 {
  background-color: #4987be !important;
  padding: 0.2rem 0;
}

.saved-search-button {
  color: #00b060;
  background: #d7ffe9;
  font-size: 0.8rem;
  border-radius: 2px;
}

.saved-search-button[data-not-selected='true'] {
  color: #00b060;
  background: #d7ffe9;
  font-size: 0.8rem;
  border-radius: 2px;
}

.ant-slider-rail {
  padding: 3px 0;
}

.profile-search-option {
  font-size: 0.875rem;
  color: #666666;
}
.profile-search-option:hover {
  cursor: pointer;
}

.profile-search-option-quantity {
  color: rgb(160, 160, 160);
}

.profile-search-filters-label {
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #333333;
}

.profile-search-clear-filter {
  cursor: pointer;
}

.filter-button {
  outline: none;
}

.clear-btn {
  color: #41479b;
}
