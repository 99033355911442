.modal-test-image {
  width: 24rem;
  height: 12rem;
  object-fit: cover;
  box-shadow: 0 0 0.25rem 0 #00000040;
  border-radius: 0.5rem;
}

.modal-test-image-open-button {
  background: none;
  border: none;
  color: #303030cc;
}
