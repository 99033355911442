.scores-chart-column-label-text {
  color: #d3d3d3;
}

.scores-chart-labels-text {
  color: #828282;
}

.score-bar-width {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
}

.score-bars-and-labels-separator {
  border-top: 3px solid #d9d9d9;
  margin: 0px 0.125rem;
}

.score-chart-container {
  height: 7rem;
}

.score-chart-bar-container {
  margin: 0px 0.125rem;
}
