.funnel-chart-content {
  border: 1px solid #b7e4fb;
  padding: 1.5rem;
}

.funnel-chart-content-progress {
  flex: 0 1 calc(65%);
}

.funnel-chart-content-label {
  flex: 0 1 calc(35%);
}

.funnel-chart-progress-bar {
  border-radius: 4px;
}
