.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }
}

@media (min-width: 768px) {
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }
}

@media (min-width: 992px) {
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }
}
