.button-trash-modal {
  border-radius: 10%;
  width: 40px;
  height: 40px;
  border: 2px solid #fd6466;
  background-color: transparent;
  color: #fd6466;
  font-size: 20px;
  text-align: center;

  &:hover {
    background-color: #fd6466;
    color: #fff;
    border: 2px solid #fd6466;
  }
}
