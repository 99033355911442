.container-search-dashboard {
  max-width: 100% !important;
  background-color: white;
}

.border-headers {
  border-bottom: 1px solid #c8c8c8;
}

.search-dashboard-header {
  position: sticky;
  top: 4rem;
  overflow-x: hidden;
  z-index: 2;
  padding-top: 1rem;
}

.search-table-header-item {
  width: max-content;
  .pinned {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 2;
  }
}

.search-table-body {
  overflow-x: hidden;
  width: 100%;
  position: static;
  padding-bottom: 20rem;
}

.table-horizontal-scroll {
  overflow-x: auto;
  position: sticky;
  bottom: 0.25rem;
  z-index: 3;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
