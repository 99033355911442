.styled-select {
  background-image: url("data:image/svg+xml, %3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M1.5 1.5L6.5 6.5L11.5 1.5' stroke='%234987BE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
  width: calc(100% - 1rem);
  background-position: calc(100% - 0.25rem) center;
  background-repeat: no-repeat;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: none;
  font-weight: 600;
  color: #828282;
  border-radius: 0.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  z-index: 1;
  &:disabled {
    background-image: none;
  }
}
