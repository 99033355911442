.container-content {
  max-width: 67rem;
  margin: 0 auto;
  height: fit-content;

  @media screen and (max-width: 1180px) {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
.abm-wrapper {
  height: calc(100vh - 5rem);
}

.view-subtitle {
  color: #4f4f4fd6;
}

.overflow-items-container {
  max-height: calc(100vh - 26rem);
}

.abm-dropdowns {
  width: 11rem;
}

.filters-container {
  height: fit-content;
  flex-wrap: wrap;
  align-items: center;
}

.abm-redirect-button {
  padding: 0.1rem 1.5rem;
  margin: auto 0;
  width: fit-content;
  border: none;
  border-radius: 16px;
  margin-left: 0.938rem;

  @media screen and (max-width: 840px) {
    margin-left: 0px !important;
  }
}

.right-buttons-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.abm-list-empty-state {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.abm-titles-container {
  padding: 0rem 1.375rem 0.25rem 1.375rem;
  position: sticky;
  top: 0;
  background-color: white;
}

.abm-metric-titles {
  width: 35.3rem;
  span {
    width: 4.3rem;
    display: inline-block;
  }
}
