@import '../../variables.scss';
.dropdown-select-container {
  font-size: 0.874rem;
  line-height: 1rem;
  color: $blue-principal;
  background-color: #fff;
  z-index: 1;
}

.dropdown-select-button {
  width: max-content;
}

.dropdown-select-arrow {
  transition: transform 0.3s;
}

.dropdown-select-arrow-open {
  transform: rotate(-180deg);
}

.dropdown-select-options-container {
  list-style: none;
  max-height: 0;
  min-width: 100%;
}

.dropdown-select-options-container-open {
  max-height: 12.5rem;
}

.dropdown-selec-option {
  list-style-type: none;
}

.dropdown-selec-option:hover {
  background-color: #fafafa;
}

.dropdown-selected-option {
  background-color: #ebebeb;
}

.dropdown-select-placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-max-height {
  max-height: 3.125rem;
}

.dropdown-container-index {
  z-index: 3;
  position: relative;
}

.dropdown-gray-bg {
  background-color: #f4f4f4;
}

.dropdown-dark-gray {
  background-color: #b1b1b1;
}

.disabled-arrow {
  filter: grayscale(100%);
}
