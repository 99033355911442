.modal-card {
  position: fixed !important;
  min-height: 200px;
  max-width: 90%;
  max-height: 80vh;
  z-index: 1050;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modal-animation 0.5s ease;
  overflow-y: scroll;
}

.overflow-y-auto {
  min-width: 800px;
  overflow-y: auto;
}

.modal-card.min-width {
  min-width: 65% !important;
}

.modal-background-custom {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1040;
}

.modal-close-button {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  line-height: 1.5rem;
  background: none;
  border: none;
  font-size: 1.625rem;
}

.modal-close-button-relative {
  position: relative;
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.625rem;
}

@keyframes modal-animation {
  0% {
    transform: translate(-50%, -50%) translateY(1.25rem);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) translateY(0);
    opacity: 1;
  }
}
@media screen and (min-width: 576px) {
  .modal-card.min-width {
    width: 90%;
  }
}

@media screen and (min-width: 1000px) {
  .modal-card.min-width {
    width: 80%;
    min-width: 0% !important;
    max-width: fit-content !important;
  }
}
