.proctoring-amount-input {
  width: 6rem;
  border-radius: 8px;
  border: 2px solid #ababab;
  outline: none;
  font-weight: 700;
  color: #3f3f3f;
  margin: 0.3rem 0rem;
}

.filters-dropdown {
  z-index: 10;
}
.proctoring-search-btn {
  margin: 0.3rem 0rem;
  border: 1px solid #58c1f5;
  font-weight: 600;
  color: #666666;
}

.filters-container-gap {
  gap: 1.75rem;
}

.border-maya-blue {
  border-color: #58c1f5 !important;
}

.profile-search-container {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
    border-color: #58c1f5 !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .ant-select-selection-placeholder,
  .input-search-dashboard {
    color: #6c757d;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
  }
  .disabled-arrow {
    filter: grayscale(100%);
  }
  .font-weight-500 {
    font-weight: 500;
  }
  .text-xs {
    font-size: 0.75rem !important;
  }
  .text-muted {
    color: #6c757d !important;
  }
}
