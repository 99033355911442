.empty-space-table-title {
  min-width: 10rem;
}

.profiles-list-title {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #272727;
}

.profiles-list-button {
  background: #4987be;
  border-radius: 1rem;
}

.profiles-list-button-text {
  font-size: 0.875rem;
  line-height: 1rem;
}

.profiles-list-titles,
.profiles-list-skill-name {
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.1rem;
  color: #424242;
}

.profiles-list-item-container {
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  min-height: 3.5rem;
  line-height: 1rem;
  color: #272727;
}

.profiles-list-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 40%;
}

.profiles-list-item-name-min-width {
  min-width: 7rem;
}

.profiles-list-item-percent {
  height: 1.375rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.07rem;
  border-radius: 20px;
  padding: 0 0.7rem;
  flex-shrink: 0;
  isolation: isolate;
}

.profiles-list-item-percent-subitem {
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 20px;
  position: absolute;
}

.profiles-list-item-percent[data-color='yellow'] {
  background-color: #ffdc57;
}

.profiles-list-item-percent[data-color='blue'] {
  background-color: #4987be;
}

.profiles-list-item-percent[data-color='green'] {
  background-color: #5edca7;
}

.dashboard-name-link {
  color: #272727;
}

.dashboard-name-link:hover {
  color: #272727;
  text-decoration: underline;
  transition: 0.5s;
}

.pill-bg {
  background: #e5e6e9;
  border-radius: 20px;
  width: 2.875rem;
  height: 1.375rem;
}
