.login-input-container {
  background-color: #f5fcff;
}

.login-input-blur {
  border: #c4c4c4 0.065rem solid;
}

.login-input-placeholder {
  padding: 0.625rem;
  pointer-events: none;
  left: 0;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

.login-input-focused {
  border: #4987be 0.065rem solid;
}

.input-login-image {
  width: 1.25rem;
}

.login-input {
  padding: 0.625rem;
  outline: none;
  font-weight: 600;
  color: #6b7280;
}

.login-input::placeholder {
  font-style: italic;
  font-weight: 400;
  font-size: 0.875rem;
}

.login-input:focus + .login-input-placeholder,
.login-input:not(:placeholder-shown) + .login-input-placeholder {
  opacity: 1;
  transform: scale(0.9) translateY(-100%) translateX(-2.5rem);
}

.border-danger {
  border: 1px solid rgb(252, 64, 64);
}
