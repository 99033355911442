th {
  width: max-content !important;
}

thead {
  position: sticky;
  top: 0px;
  background: #f8f9fa;
  box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.2);
}

.react-bootstrap-table th .caret-4-desc::after,
.react-bootstrap-table th .caret-4-asc::after,
.react-bootstrap-table th .caret-4-desc::before,
.react-bootstrap-table th .caret-4-asc::before,
.react-bootstrap-table th .order-4::before,
.react-bootstrap-table th .order-4::after {
  content: '' !important;
  width: 0;
  height: 0;
  border-style: solid;
  display: inline-block;
  margin-bottom: 2px;
}

.react-bootstrap-table th .caret-4-desc::after {
  border-width: 6px 4px 0 4px;
  border-color: #181818 transparent transparent transparent;
}

.react-bootstrap-table th .caret-4-asc::after,
.react-bootstrap-table th .order-4::after {
  border-width: 6px 4px 0 4px;
  border-color: #727272 transparent transparent transparent;
}

.react-bootstrap-table th .caret-4-desc::before,
.react-bootstrap-table th .order-4::before {
  border-width: 0px 4px 6px 4px;
  border-color: transparent transparent #727272 transparent;
}

.react-bootstrap-table th .caret-4-asc::before {
  border-width: 0px 4px 6px 4px;
  border-color: transparent transparent #181818 transparent;
}

.react-bootstrap-table table {
  table-layout: fixed !important;
}

.react-bootstrap-table table,
.table-responsive table {
  background: #fff;
}
