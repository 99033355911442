.modal-container {
  position: fixed !important;
  width: 35.313rem;
  min-height: 17.75rem;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modal-animation 0.5s ease;
  background: #ffffff;
  padding: 3.125rem 3rem 2.5rem 3rem;
  border-radius: 8px;
}

.modal-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}

.modal-subtitle {
  color: #5f5f5f;
}

.close-button {
  border: none;
  width: fit-content;
  margin: auto;
}

.modal-mail-button {
  background: #4987be;
  border: 0px;
  width: fit-content;
  border-radius: 22px;
  padding: 0.5rem 2rem;
  color: white;
}

.body-modal-open {
  overflow: hidden !important;
}

.buttons-container {
  margin-top: 1rem;
}

@keyframes modal-animation {
  0% {
    transform: translate(-50%, -50%) translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) translateY(0);
    opacity: 1;
  }
}
