.create-jobpost-img {
    width: 4rem;
    height: 4rem;
    border: 0.5px solid #a8a8a8;
}

.container-dropdown-jopbpost {
    container: initial;
    width: 100%;
    justify-content: start;
    display: block;
    margin-bottom: 15px;
}

.job-post-upload-icon {
    width: 3rem;
    height: 3rem;
}

.jobpost-delete-file-icon:hover {
    cursor: grabbing;
    fill: brown;
    z-index: 99;
}

.col-md-5 {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.jobpost-file-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.container-dropdown-techs-jopbpost .ant-select-selection-placeholder {
    font-weight: 500;
    color: #14141480;
    margin-bottom: 3rem;
}

.new-job-container .ql-container.ql-snow {
    overflow-y: auto;
    max-height: 30vh;
}