.proctoring-amount-input {
  width: 6rem;
  border-radius: 8px;
  border: 2px solid #ababab;
  outline: none;
  font-weight: 700;
  color: #3f3f3f;
  margin: 0.3rem 0rem;
}

.proctoring-search-btn {
  margin: 0.3rem 0rem;
  border: 1px solid #4987be;
  font-weight: 600;
  color: #666666;
}

.filters-container-gap {
  gap: 1.75rem;
}
