.hand {
  animation: hand 2s infinite;
}

@keyframes hand {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.line-1 {
  animation: line-1 2s infinite;
}

@keyframes line-1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

.line-2 {
  animation: line-2 2s infinite;
}

@keyframes line-2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 100px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.line-3 {
  animation: line-3 2s infinite;
}

@keyframes line-3 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(50px, 100px);
  }
  100% {
    transform: translate(0);
  }
}

.line-4 {
  animation: line-4 2s infinite;
}

@keyframes line-4 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-100px, 100px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.interface {
  animation: interface 2s infinite;
}

@keyframes interface {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
