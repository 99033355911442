@import '../../variables.scss';

.progress-container {
  z-index: 2;
}

.progress-training {
  height: 0.3125rem;
  background: linear-gradient(89.98deg, $blue-principal 0%, #9bf55a 99.98%);
  z-index: 2;
  transition: width 1s 0.5s ease;
}

.progress-training-with-bg {
  background: linear-gradient(89.98deg, $blue-principal 0%, #9bf55a 99.98%);
}

.progress-training-bg {
  height: 0.3125rem;
  z-index: -1;
  background: #ebebeb;
  top: 0%;
  left: 0%;
}

.transition {
  height: 0.3125rem;
  z-index: 2;
  transition: width 1s 0.5s ease;
}

.bg-secondary-contrast {
  background: #94e9c6;
}

.bg-yellow-contrast {
  background: #58dda6;
}

.bg-green-contrast {
  background: #49b588;
}
