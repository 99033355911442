$grey-1: #686868;
$grey-2: #bcbcbc;
$grey-3: #ececec;
$grey-4: #d9d9d9;
$violet-secundary: #41479b;
$blue-secondary: #0e9ce2;
$blue-dark: #357b9eff;
$blue-mid: #79a6cf;
$blue-principal: #4987be;
$blue-light: #a5c4e0;
$orange-error: #ff852c;
$grey-secondary: #4f4f4f;
