@import '../../variables.scss';

.switch {
  width: 2.75rem;
  min-width: 2.75rem;
  height: 1.5rem;
  border-radius: 20px;
}

.switch-active {
  background-color: $blue-principal;
  transition: color 0.5s;
}

.switch-inactive {
  background-color: #c7c7c7;
  transition: color 0.5s;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  height: 1rem;
  width: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider::after {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 1.45rem;
  bottom: -4px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 0.3s;
}

input:checked + .slider::after {
  transform: translateX(-1.15rem);
  transition: 0.3s;
}

.toggle-selected-option {
  color: #272727;
  transition: color 0.5s;
}

.toggle-unselected-option {
  color: #939393;
  transition: color 0.5s;
}

.cursor-loading {
  cursor: wait !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.switch-container {
  height: fit-content;
}
