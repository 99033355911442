@import '../../variables.scss';

.border-blue-dark {
  border-bottom: 2px solid $blue-dark !important;
}

.orange-error {
  color: $orange-error !important;
}

.weight-success {
  color: $blue-principal !important;
}

.container-additional-configuration {
  height: 20rem;
}

@media (min-width: 1200px) {
  .container-additional-configuration {
    height: 30rem;
  }
}

@media (min-width: 1500px) {
  .container-additional-configuration {
    height: 40rem;
    /* Ajuste para pantallas medianas */
  }
}

@media (min-width: 1800px) {
  .container-additional-configuration {
    height: 52rem;
    /* Ajuste para pantallas pequeñas */
  }
}

.columnsLangCodes {
  gap: 1rem;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.columnsLangCodes > div {
  flex: 1;
  text-align: left;
  padding-left: 1rem;
  border-right: 1px solid #e0e0e0;
  padding-right: 2.5rem;
}

.columnsLangCodes > div:last-child {
  border-right: none;
}
