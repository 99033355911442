@import '../../variables.scss';
.size-card {
  width: 19.5rem;
}

.header-color {
  border-radius: 8px 8px 0px 0px;
}

.footer-card-workspace {
  height: 5rem;
}

.badge-role {
  height: fit-content;
  background-color: #d9d9d9;
  border-radius: 2px;
}

.workspace-role-text {
  color: $grey-1;
}

.text-badge-color-owner {
  background-color: #cae8db;
  color: #3d8c6b;
}

.mt-6 {
  margin-top: 5rem;
}

.button-option-workspace {
  border-radius: 0.25rem;
  background-color: $grey-4;
  svg {
    width: 1rem !important;
    height: auto;
  }
  path {
    fill: black;
  }
  &:hover {
    background-color: $grey-3;
    border-radius: 0.25rem;
  }
}

.workspace-button-text {
  color: $violet-secundary !important;
  text-decoration: underline;
}
