@import '../../variables.scss';

.ticket-status-search {
  font-weight: 700;
  font-size: 0.68rem;
  line-height: 0.935rem;
  letter-spacing: 0.07em;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  width: fit-content;
}

.ticket-on {
  color: #3d8c6b;
  background: #cae8db;
}

.ticket-off {
  background: #ffe2e3;
  color: #fd6466;
}

.ticket-draft {
  background: #d9d9d9;
  color: #5f5f5f;
}

.screening-search-container {
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 6px 0px #00000060;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.empty-screening-search-container {
  box-sizing: border-box;
  border-radius: 8px;
}

.button-see-more-search {
  background: rgba(198, 198, 198, 0.21);
  border-radius: 16px;
}

.text-see-more-search {
  color: #222222;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
}

.text-see-more-search:hover {
  text-decoration: none;
}

.text-description-screening {
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
  color: #222222;
}

.text-create-search-screening {
  font-size: 0.875rem;
  line-height: 1.065rem;
  letter-spacing: 0.01rem;
  color: #828282;
}

.text-underline {
  text-decoration-line: underline;
}

.description-card-search {
  height: 2.5rem;
}

.modal-buttons-border {
  border-radius: 1rem;
}

.hover-danger {
  &:hover {
    background-color: #fd6466;
  }
}

.dont-delete-draft-button-border {
  border: 0.063rem solid;
}

.title-screening {
  white-space: initial;
  line-height: 130%;
  letter-spacing: -0.019em;
  padding-top: 0.75rem;
  color: #292929ec;
}

.footer-card {
  border-radius: 0px 0px 8px 8px;
  padding: 0.625rem;
  background: $grey-3 !important;
  color: $blue-dark;

  g {
    fill: $blue-dark !important;
    stroke-width: 1;
  }
}

.w-90 {
  width: 90%;
}

.bullets-button {
  margin-top: -0.938rem;
}

.created-by-position {
  width: 15rem;
  white-space: nowrap;
  position: absolute;
  bottom: 3.438rem;
}

.position-texts {
  position: absolute;
  top: 6rem;
  left: 0rem;
  right: 0rem;
}

.arrow-invite-position {
  position: absolute;
  left: 18rem;
  bottom: 3.5rem;
}

.position-dots {
  position: absolute;
  right: 0.4rem;
}

.width-selectable {
  width: 13rem !important;
}

button:disabled {
  opacity: 0.75;
  cursor: not-allowed;
  border: 0;
  background: inherit;
}

.displayNone {
  display: none;
}

#move-screening-container .ant-select-item-option-content {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.screening-title-container {
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;

  &.open-bg {
    background-color: $blue-light !important;
  }

  &.closed-bg {
    background-color: $grey-2 !important;
  }

  &.draft-bg {
    background-color: $grey-3 !important;
  }

  transition: all 0.3s ease-in-out;
}

.screening-title-container:hover {
  transition: all 0.3s ease-in-out;

  &.open-bg {
    background-color: lighten($blue-light, 3%) !important;
  }

  &.closed-bg {
    background-color: lighten($grey-2, 10%) !important;
  }

  &.draft-bg {
    background-color: lighten($grey-3, 10%) !important;
  }

  .title-screening {
    color: #000000;
    text-decoration: underline !important;

    a {
      text-decoration: underline !important;
    }
  }
}

.card-screenings {
  width: 100%;
  height: 6.5rem !important;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  border: none;
}

.card-screenings.flipped {
  transform: rotateY(180deg);
}

.card-front-screenings,
.card-back-screenings {
  display: flex;
  width: 100%;
  height: 100% !important;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  padding: 0 1rem;
}

.card-front-screenings {
  padding: 0 3rem !important;
}

.card-back-screenings {
  transform: rotateY(180deg);
}

.color-grey-1 {
  color: $grey-1;
}

.color-principal {
  color: $blue-principal;
}

.border-bottom-dotted {
  border-bottom: 1px dotted $blue-light;
}

.hover-arrow-page:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.hover-arrow-page {
  transition: transform 0.2s ease-in-out;
}
