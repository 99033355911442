.dropdown-menu-text {
  font-size: 1rem;
  line-height: 3.875rem;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.dropdown-menu-responsive {
  width: 18.5rem;
  z-index: 13;
  background-color: #011528;
  transform: translateX(100%);
  transition: transform 0.5s;
}

.dropdown-active {
  height: 100vh;
  transform: translateX(0);
  transition: transform 0.5s;
}

.menu-responsive-icon {
  width: 1.125rem;
  height: 1.125rem;
}

.responsive-sidebar-list-container {
  height: calc(100% - 8rem);
}
