.maintenance-title {
  font-size: 2.25rem;
  line-height: 2.375rem;
  color: #222222;
}

.maintenance-subtitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: #828282;
}

.maintenance-button {
  padding: 0.565rem 1rem;
  background: #4987be;
  border-radius: 1rem;
}

.maintenance-button-text {
  font-size: 0.875rem;
  line-height: 1rem;
}
