.confirm-modal-container {
  max-width: 22rem;
  cursor: auto;
}

.show-confirm-modal {
  transition: all 0.2s;
  opacity: 1;
  bottom: 2.6rem;
}

.not-show-confirm-modal {
  transition: all 0.2s;
  width: 0;
  opacity: 0;
}

.button-confirm-modal {
  background: #4987be;
  border: none;
  border-radius: 2rem;
  padding: 0.4rem;
}

.button-confirm-modal-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #ffffff;
}

.button-cancel-modal {
  background: #ffffff;
  border: solid 1px #4987be;
  border-radius: 2rem;
  padding: 0.315rem 0rem;
}

.button-cancel-modal-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #4987be;
}
