.header {
  height: 3rem;
  background: #ffffff;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
  position: sticky !important;
  top: 0 !important;
  z-index: 12;
}

.logo {
  width: 7.5rem;
  height: auto;
}

@media screen and (max-width: 992px) {
  .logo {
    width: 5.5rem;
  }
}

.dropdown-logo {
  width: 2rem;
  height: 2rem;
}

.dropdown-name {
  margin: auto 0.75rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #182c4c;
}

.dropdown-menu-burger {
  width: 1.125rem;
  height: 0.875rem;
}

.header-toggle {
  height: fit-content;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .header-toggle {
    display: none;
  }
}

.hoverable-btn,
.hoverable-btn:hover {
  transition: all ease 0.1s !important;
  cursor: pointer;
  text-decoration: none;
}

.schedule-demo-btn,
.schedule-demo-btn:visited {
  color: #757575 !important;
}

.calendar-basic-icon {
  fill: #757575 !important;
}

.demo-icon {
  fill: #757575 !important;
}

.demo-onboarding {
  background-color: transparent;
  border: none;
}

.schedule-demo-btn-container {
  &:hover {
    .schedule-demo-btn {
      color: #4987be !important;
    }

    .calendar-basic-icon {
      fill: #4987be !important;
    }

    .demo-icon {
      fill: #4987be !important;
    }
  }
}

.calendar-basic-icon-margin {
  margin-bottom: 0.125rem;
}
