.logged-out-container {
  height: 100vh;
}

.logged-out-layout-title {
  font-size: 1.25rem;
}

.steps-children-container {
  max-width: 33.5rem;
}

.logged-out-layout-subtitle {
  font-weight: 600;
  color: #5c5c5c;
}

.logged-out-bg-image {
  max-height: 100vh;
  background-size: cover;
  background-position: center;
}

.logged-out-children-container {
  max-height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.logged-out-children-container::-webkit-scrollbar {
  display: none;
}

.login-title,
.login-subtitle {
  color: #222222;
}

.logged-out-logo {
  width: 140px;
}

.login-content-margin {
  margin: 0.625rem;
}

.demo-section-container {
  border: 0.063rem solid #bcbcbc;
  border-radius: 16px;
  height: 6.25rem;
  min-height: 6.25rem;
}

.toggle-have-account-text {
  color: #5f5f5f;
}

.need-help-text {
  color: #757575;
}
