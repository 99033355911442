@import '../../variables';

.ant-steps-icon {
  // font-family: 'Montserrat';
}

.ant-steps-item-title {
  // font-family: 'Montserrat';
  font-weight: 500;
}

.ant-steps-item .ant-steps-item-icon {
  background-color: #e6e6e6;
  border: 0;
}

.ant-steps-item {
  &.ant-steps-item-wait {
    .ant-steps-item-container:hover {
      .ant-steps-item-icon .ant-steps-icon {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  &.ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: $blue-principal;
    }

    .ant-steps-item-title {
      color: $blue-principal !important;
      font-weight: 600;
    }

    .ant-steps-item-container:hover {
      .ant-steps-item-icon .ant-steps-icon {
        color: white !important;
      }
    }
  }

  &.ant-steps-item-finish {
    .ant-steps-item-icon {
      line-height: 1.625rem;
      background: $blue-principal;

      svg {
        color: white;
      }
    }
  }
}

.ant-steps-item-icon .ant-steps-icon {
  top: -0.063rem;
  font-weight: 600;

  &:hover {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-steps-item-title {
  font-weight: 500;
  color: #828282 !important;
}

.ant-steps-item-title:hover {
  color: #828282 !important;
}

.ant-steps-item-title::after {
  height: 0.094rem;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $blue-principal;

  .ant-steps-item-title {
    color: $blue-principal !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: $blue-principal !important;
      }
    }
  }
}

.bottom-navigator-button {
  border: 0.125rem solid;
  border-radius: 50%;
}

.create-evaluation-custom-modal {
  width: 12.5rem;
  min-width: 0px !important;

  .modal-close-button {
    font-size: 2rem;
  }
}

.create-evaluation-step-title {
  font-size: 1.5rem;
}

.create-evaluation-datepickers {
  width: 11rem;
}

.selected-role-button {
  color: #303030;
  opacity: 0.8;
  border-radius: 0.5rem;
  border: 1px solid #4987be;
}

.create-evaluation-role-dropdown {
  min-width: 31rem;
  border-radius: 0.5rem !important;
  border-color: #4987be !important;
  align-self: self-start !important;

  .dropdown-select-button {
    padding: 0.625rem 1rem !important;
  }

  input::placeholder {
    font-size: 0.75rem;
    color: rgba(48, 48, 48, 0.8);
  }
}

.first-step-role-dropdown {
  width: 25rem !important;
  border-radius: 0.25rem !important;
  border-color: #bbbbbb !important;

  .dropdown-select-button {
    padding: 0.625rem 1rem !important;
  }

  input::placeholder {
    font-size: 0.75rem;
    color: rgba(48, 48, 48, 0.8);
  }
}

.create-evaluation-secondary-button {
  color: #4987be;
  border: 1px solid #4987be;
  border-radius: 30px;
}

.alkemy-tests-empty-state-texts {
  width: 55%;
}

.create-evaluation-gray-underline {
  text-decoration: underline rgba(48, 48, 48, 1);
}

.available-weight-custom-height {
  height: 1.5rem;
}

.unassigned-weight-assigned-text {
  color: #dd361e;
}

.all-weight-assigned-text {
  color: #3d8c6b;
}

.evaluation-name-input {
  width: 40%;
}

.evaluation-purpose-select {
  width: 14.75rem;
  height: 2.28rem;
  box-shadow: 0rem 0.063rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.download-certificate-optional-text {
  color: #ffffff;
}

.window-height {
  height: 52rem;
}

.window-height-step3 {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.fourth-step-premium-functions {
  position: absolute;
  right: 5%;
  z-index: 100;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  height: 3rem;
  width: 3rem;
}

.screening-name-title {
  color: #303030cc;
}

.screening-name-input {
  border: 1.5px #bbbbbb solid;
  outline: none;
}

.screening-name-input::placeholder {
  color: #4b4b4bbf;
  font-size: 0.875rem;
}

.screening-name-input.completed {
  border-color: $blue-principal;
}

.create-screening-section-container {
  width: 100%;
}

.disabled-button {
  background-color: #828282;
}

.create-screening-container {
  min-height: 65vh;
}

@media screen and (min-width: 768px) {
  .create-screening-section-container {
    width: 50%;
  }
}

.screening-name-title {
  color: #303030cc;
}

.screening-name-input {
  border: none;
  border-bottom: 2px $grey-2 solid;
  outline: none;
}

.screening-name-input::placeholder {
  color: $grey-1;
  font-size: 0.875rem;
  font-weight: 500;
}

.screening-name-input.completed {
  border-color: $blue-principal;
}

.create-screening-section-container {
  width: 100%;
}

.disabled-button {
  background-color: $grey-4 !important;
}

.create-screening-container {
  min-height: 65vh;
}

.create-screening-dropdown-language {
  color: $grey-1;
  border: none !important;
  border-bottom: 2px $grey-2 solid !important;
  outline: none;
  margin-left: 5rem;
  font-weight: 500;

  .dropdown-select-placeholder {
    padding-right: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .create-screening-section-container {
    width: 50%;
  }
}

.border-item-search-type {
  border: 0.3125rem solid $blue-light;
  border-radius: 0.5rem;
  cursor: pointer !important;
  transition: all 0.3s ease;
}

.border-item-search-type:hover {
  border-color: $blue-principal;
}

.button-back-create-screening {
  color: $blue-principal;
  font-weight: 700;
  border-color: $blue-light;
}

.button-back-create-screening:hover {
  color: $blue-principal;
  border-color: $blue-principal;
}

.selected-search-type {
  border: 0.3125rem solid $blue-principal;
  background-color: $blue-principal;
  border-radius: 0.5rem;
  cursor: pointer !important;
}

.checkbox.create-evaluation-checkbox input,
.checkbox.create-evaluation-checkbox svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.checkbox.bounce.create-evaluation-checkbox input {
  border: 2px solid $blue-principal;
  --b: $blue-principal;
  background-color: white !important;
}

.checkbox.bounce.create-evaluation-checkbox input:checked {
  border: 2px solid $blue-principal;
  --s: 1rem;
  --b: transparent;
}

.checkbox.bounce.create-evaluation-checkbox input:checked + svg polyline {
  fill: none;
  stroke: $blue-principal !important;
}

.select-width {
  width: 10%;
}

.first-step-question-mark {
  width: 8rem;
}

.type-of-search-option-icon {
  width: 100%;
  max-width: 10rem;
}

.fixed-container {
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
}
