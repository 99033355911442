@import '../../variables.scss';
.screening-page-container {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.screening-card-container {
  min-height: 11.875rem;
}

.screening-card {
  background: #fff;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  border: 2px solid #222222;
}

.text-gray-screening {
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
}

.text-screening-technology {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #272727;
}

.button-apply-screening {
  background: #ffffff;
  border: solid 1px #4987be;
  border-radius: 2rem;
  padding: 0.315rem 0rem;
}

.button-apply-screening-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #4987be;
}

.button-apply-screening[data-selected='true'] {
  background: #58dda6;
  border: solid 1px #58dda6;
}

.button-apply-screening[data-selected='true'] .button-apply-screening-text {
  color: #ffffff;
}

.button-screening-continue {
  background: #4987be;
  border: none;
  border-radius: 2rem;
  padding: 0.4rem;
}

.button-screening-continue:disabled {
  background: #4987be8c;
}

.button-screening-continue-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #ffffff;
}

.ticket-screening-text {
  font-size: 0.815rem;
  line-height: 0.935rem;
  letter-spacing: 0.07em;
  padding: 0.175rem;
  border-radius: 0.125rem;
  color: #176b71;
  background: #d0f0ff;
}

.ticket-padding {
  font-size: 0.815rem;
  line-height: 0.935rem;
  letter-spacing: 0.07em;
  padding: 0.175rem;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
}

.title-screening-color {
  color: #222222;
}

.subtitle-screening {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.ticket-technology-frontend {
  color: $blue-principal;
  background: #4987be32;
}

.ticket-technology-fullstack {
  color: #fd6466;
  background: #ffe2e3;
}

.button-screening-cancel {
  background: #fd6466;
  border: none;
  border-radius: 2rem;
  padding: 0.4rem;
}

.button-screening-cancel:disabled {
  background: #fd646675;
}

.button-screening-cancel-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #ffffff;
}

.button-screening-new-row {
  background: #ffffff;
  border: solid 1px #4987be;
  border-radius: 2rem;
  padding: 0.4rem 0rem;
}

.button-screening-new-row-text {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: #4987be;
}

.button-screening-new-row:disabled {
  border: solid 1px #4987be67;
}

.button-screening-new-row:disabled .button-screening-new-row-text {
  color: #4987be67;
}

.button-back-invitation {
  top: 0.825rem;
  position: relative;
}

@media (min-width: 992px) {
  .button-back-invitation {
    position: absolute;
  }
}

.search-bar-screenings {
  outline: none;
  border: none;
  border-bottom: 2px solid $grey-2;
  padding: 0.3rem;
  font-weight: 500;
  font-size: 0.875rem;
  input {
    border: none;
    outline: none;
  }
}

.screenings-tree-filters {
  .ant-tree-checkbox-inner {
    background-color: transparent;
    border: 2px solid $blue-principal;
  }
  .ant-tree-checkbox-inner:hover {
    border-color: $blue-principal;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: $blue-principal;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    border-color: $blue-principal;
  }
  .ant-tree-treenode {
    max-width: 100%;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.screening-filters-title {
  svg {
    color: $blue-dark;
  }
}

.border-filters {
  border: 1px solid $grey-4;
  .border-bottom-filter {
    border-bottom: 1px solid $grey-2;
  }
}

.bg-principal {
  background-color: $blue-principal !important;
}
