@import '../../variables.scss';

.percentage-input-width {
  width: 4rem;
}

.disabled-weight-input {
  color: rgba(75, 75, 75, 0.25);
  border: 0.065rem solid rgba(75, 75, 75, 0.25);
  border-radius: 8px;
}

.testgroup-description-border {
  border: 0.2rem solid #f4f4f4;
  border-radius: 0px;
}

.weight-input-custom-width {
  width: 5.125rem;
}

.qualitative-label {
  color: #828282;
}

.qualitative-label-width {
  width: 8.125rem;
}

.quick-config-container {
  background-color: $grey-3;
  border-radius: 4px;
  span {
    color: $grey-1;
  }
}

.container-min-height {
  min-height: 4rem;
}

p {
  margin: 0;
}
