.not-approved-input {
  outline: none;
  border-radius: 8px;
  padding: 0.9rem;
}

.active {
  border: 1px solid #4987be;
}

.disabled {
  border: 1px solid #828282;
}

.not-approved-input:read-only {
  cursor: not-allowed;
}

.error-message {
  color: #ea182c;
}

.not-approved-toggle {
  outline: none;
  border-radius: 8px;
  padding: 0.9rem;
}
