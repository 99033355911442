.horizontal-bars-row-height {
  height: 2rem;
}

.horizontal-bars-bar-section {
  height: 2rem;
  z-index: 1;
}

.horizontal-bar-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
  max-width: 5.8rem;
  text-align: end;
}

.horizontal-bar-column-value {
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
  width: 0;
}

.horizontal-bar-chart-lines {
  border-top: 2px solid #d9d9d9;
}

.horizontal-bar-column-value:before {
  content: '';
  position: absolute;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  bottom: 1.8rem;
  height: calc(100% - 1.8rem);
}

.horizontal-bar-column-value:first-child:before {
  display: none;
}
