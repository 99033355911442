@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700&display=swap');
@import './variables.scss';

body {
  margin: 0;
  font-family: 'Mulish', sans-serif !important;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.w-80vw {
  width: 80vw !important;
  max-width: 1000px !important;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.body-modal-open {
  position: fixed;
  overflow-y: scroll !important;
}

.text-light-black {
  color: #303030;
}

@media screen and (min-width: 576px) {
  .w-80vw {
    width: 90% !important;
  }
}

@media (min-width: 768px) {
  .px-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (min-width: 992px) {
  .px-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .px-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
}

.ant-tooltip-inner a {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-normal {
  cursor: default !important;
}

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content !important;
}

.h-80 {
  height: 80% !important;
}

.left-0 {
  left: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-title {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-xxl {
  font-size: 1.5rem !important;
}

.text-large {
  font-size: 1.125rem !important;
}

.text-base {
  font-size: 0.875rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xxs {
  font-size: 0.625rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-12-px {
  font-size: 0.75rem !important;
}

.font-tiny {
  font-size: 0.6rem !important;
}

.outstanding-text {
  letter-spacing: 1px;
}

$weight: 100;

@while $weight <=1000 {
  .font-weight-#{$weight} {
    font-weight: $weight;
  }

  $weight: $weight + 100;
}

.border-thin-blue-principal {
  border: 0.065rem solid $blue-principal;
  border-radius: 8px;
}

.dotted-border-bottom {
  border-bottom: 0.125rem dotted $blue-principal !important;
}

.size-checkbox {
  height: 1.375rem;
  width: 1.375rem;
}

.transition-2-ms {
  transition: 0.2s;
}

.font-mulish {
  font-family: Mulish, sans-serif;
  font-style: normal;
}

.rotate-180 {
  transform: rotate(180deg);
}

.object-fit-contain {
  object-fit: contain;
}

@for $percent from 0 to 10 {
  .z-index-#{$percent} {
    z-index: $percent;
  }
}

.bg-gray {
  background-color: #f4f4f4;
}

.border-maya-blue {
  border-color: #4987be !important;
}

.border-blue-principal {
  border-color: $blue-principal !important;
}

.border-red-danger {
  border-color: #dc251c !important;
}

.text-red-danger {
  color: #dc251c !important;
}

.text-muted-alkemy {
  color: #4f4f4f;
}

.bg-maya-blue {
  background-color: #4987be !important;
}

.bg-maya-blue.disabled {
  background-color: #828282 !important;
}

.bg-disabled-button {
  background-color: #828282 !important;
}

.bg-dark-maya-blue {
  background-color: #4987be !important;
}

.bg-blue-principal {
  background-color: $blue-principal !important;
}

.bg-blue-light {
  background-color: $blue-light !important;
}

.bg-blue-dark {
  background-color: $blue-dark !important;
}

.bg-blue-secondary {
  background-color: $blue-secondary !important;
}

.bg-grey-1 {
  background-color: $grey-1 !important;
}

.bg-grey-2 {
  background-color: $grey-2 !important;
}

.bg-grey-3 {
  background-color: $grey-3 !important;
}

.bg-grey-4 {
  background-color: $grey-4 !important;
}

.color-blue-principal {
  color: $blue-principal !important;
}

.color-blue-secondary {
  color: $blue-secondary !important;
}

.border-dark-maya-blue {
  border-color: #4987be !important;
}

.description-container {
  max-width: 65rem;
}

.text-dark-maya-blue {
  color: #4987be !important;
}

.text-dark {
  color: #1a1a1a !important;
}

.text-blue-principal {
  color: $blue-principal !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #cacaca #eeeeee;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $blue-principal !important;
  border-radius: 10px;
  border: 1px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0077cc;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eeeeee;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: $blue-principal !important;
  border-radius: 20px;
  border: 3px solid #eeeeee;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.unstyled-button {
  background: none;
  border: none;
}

.border-none {
  border: none;
}

.break-before {
  break-before: always;
}

.w-min-content {
  width: min-content;
}

.w-max-content {
  width: max-content;
}

.min-width-100 {
  min-width: 100%;
}

.w-85 {
  width: 85%;
}

.w-60 {
  width: 60%;
}

.text-gray-2 {
  color: #4f4f4f;
}

.text-gray-4 {
  color: #757575;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

@media screen and (min-width: 768px) {
  .border-md-right {
    border-right: 1px solid #dee2e6 !important;
  }
}

@media screen and (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.border-container {
  border: 1.5px solid rgba(26, 172, 240, 0.52);
  border-radius: 8px;
}

.border-container-gray {
  border: 1.5px solid #bcbcbc;
}

.italic {
  font-style: italic;
}

.b-radius-8 {
  border-radius: 8px !important;
}

.b-left-radius-8 {
  border-radius: 8px 0px 0px 8px !important;
}

.b-right-radius-8 {
  border-radius: 0px 8px 8px 0px !important;
}

.line-height-20 {
  line-height: 1.25rem;
}

.line-height-17 {
  line-height: 1.063rem;
}

.line-height-14 {
  line-height: 0.914rem;
}

.letter-spacing-05 {
  letter-spacing: -0.031rem;
}

.gray-subtitle {
  color: rgba(79, 79, 79, 0.84);
}

.secondary-contrast-color {
  color: rgb(52, 48, 248);
}

.blue-color {
  color: rgba(65, 71, 168, 1);
}

.red-color {
  color: rgba(220, 37, 28, 1);
}

.custom-date-button {
  width: 9.375rem;
  height: 2.375rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.063rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(239, 240, 244, 1);
  color: rgba(24, 44, 76, 1);
}

.border-gray {
  border: 1px solid #c9c9c9;
}

.text-purple {
  color: #4987be;
}

.ant-btn-primary {
  background-color: $blue-principal !important;
  color: white !important;
  border: 0;
}

.bg-yellow-warning {
  background: rgba(255, 211, 41, 0.6);
}

.bg-disabled {
  background-color: #828282;
}

.text-disabled {
  color: #828282;
}

.bg-red-danger {
  background: #dc251c;
}

.time-picker-button {
  width: 8.5rem;
  height: 2.28rem;
  box-shadow: 0rem 0.063rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  img {
    margin-top: 0.4rem;
  }

  .dropdown-select-options-container-open {
    max-height: 8.5rem;
  }
}

.time-picker-button-small {
  .dropdown-select-options-container-open {
    max-height: 6.2rem;
  }
}

.text-black {
  color: black !important;
}

.header-vertical-line {
  border-left: 0.125rem solid #d9d9d9;
  height: 2.375rem;
}

.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.gap-1 {
  gap: 0.625rem;
}

.gap-2 {
  gap: 1.25rem;
}

.gap-3 {
  gap: 1.875rem;
}

.gap-4 {
  gap: 2.5rem;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
