.age-gender-chart-total {
  font-size: 2rem;
  line-height: 110%;
  letter-spacing: -0.019em;
  margin-bottom: 0.75rem;
}

.age-gender-legend-circle {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 1rem;
}

.age-gender-legend-container {
  margin-bottom: 0.5rem;
}

.age-gender-legend-container:last-child {
  margin: 0;
}

.age-gender-chart-total-text {
  color: #828282;
  font-size: 0.875rem;
  line-height: 80%;
}

.age-gender-legend {
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 500;
}

.invert-axes-text {
  color: #828282;
  font-size: 0.875rem;
  font-weight: 600;
}
