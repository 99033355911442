@import '../../variables.scss';

.checkbox.test-checkbox input,
.checkbox.test-checkbox svg {
  width: 1.625rem;
  height: 1.625rem;
}

.checkbox.bounce.test-checkbox input {
  border: 2px solid $blue-principal;
  // --b: $blue-principal;
  // box-shadow: inset 0 0 0 1px $blue-principal;
  background-color: transparent;
}

.checkbox.bounce.test-checkbox input:checked {
  border: 2px solid $blue-principal;
  --s: 1rem;
  --b: $blue-principal;
  box-shadow: inset 0 0 0 1rem $blue-principal;
}

.test-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: 0.2s;
  border: #bcbcbc 2px solid;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.219rem;
  background-color: #ffffff;
  margin-top: 2.3rem;
  width: 100%;
  max-width: 27.75rem;
  height: 25rem;
  padding: 0.7rem 1.5rem 0;
  box-sizing: border-box;
  box-shadow: 4px 5px 5px 0px #00000059;
  transition: all 0.3s ease-in-out;
}

.align-content-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -6rem;
  margin-bottom: -3rem;
}

.test-item-container:hover {
  transition: all 0.2s ease-in-out;
  box-shadow: 4px 5px 10px 0px #0c0c0c7e;
}

@media screen and (max-width: 1160px) {
  .test-item-container {
    height: 30rem;
  }
}

.card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  border: none;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  display: flex;
  width: 100%;
  height: 13.813rem;
  max-height: 13.813rem !important;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  padding-top: 0.8rem;
}

.card-back {
  transform: rotateY(180deg);

  p,
  span {
    color: #686868;
    margin: 0;
    padding: 0;
  }

  .statistics-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    width: 100%;

    .statistics-content {
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: flex-start;
      font-size: 0.75rem;
      width: 100%;

      .stars-averages,
      .bold-titles {
        font-size: 0.625rem;
        flex-wrap: wrap;
      }

      .stars-averages {
        font-weight: 400;
        display: flex;
        flex-direction: column;

        .item {
          gap: 0.5rem;
          display: flex;
        }

        .text {
          font-size: 0.625rem;
          font-weight: 600;
        }

        .average {
          padding-right: 0.3rem;
          font-weight: 400;
        }
      }

      .bold-titles {
        font-weight: 600;
        max-width: 4.375rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .endorsement-container {
    display: flex;
    width: 100%;

    .used-by,
    .created-by {
      display: flex;
      flex-direction: column;
      width: 50%;
      font-size: 0.75rem;
      font-weight: 600;
    }

    .image-wrapper {
      position: relative;
      width: 4.6rem;
      height: 4.6rem;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .image-wrapper::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: inherit;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(10px);
      z-index: 0;
    }

    .image-wrapper img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 1;
    }

    .img-endorsement {
      width: 4.6rem;
      height: 4.6rem;
      border-radius: 50%;
    }

    .item-content {
      display: flex;
      gap: 1rem;
      width: 100%;
      height: 4.625rem;
      align-items: center;
      justify-content: center;
    }

    .item-texts {
      display: flex;
      flex-direction: column;
      color: #272727;
      text-overflow: ellipsis;
      width: 7rem;
      height: 6rem;

      h3 {
        font-size: 0.75rem;
        height: 20%;
      }

      p {
        font-size: 0.625rem;
        height: 80%;
        line-height: 0.762rem;
      }
    }
  }
}

.selected {
  border: 6px solid #58dda6;
}

.test-labels-container {
  background-color: #f5f6f6;
  font-size: 0.75rem;
  border-radius: 0.4rem;
  padding: 0.1rem 0.5rem;
}

.objective-pill {
  background-color: #bae2f6;
}

.role-pill {
  background-color: #bcecd8;
}

.objective-pill,
.role-pill {
  border-radius: 6rem;
  padding: 0.08rem 0.6rem;
  gap: 0.5rem;
}

.dotted-border {
  border: 1px dashed #bae2f6;
  border-radius: 1px;
  width: 100%;
  margin-bottom: 0.8rem;
}

h3 {
  font-size: 0.75rem;
  font-weight: 600;
}

.texts {
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  align-self: center;
  font-weight: 400;
}

.testgroup-footer {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  padding-bottom: 1.5rem;
}

.footer-item {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
}

.details-button {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 5.875rem;
  transform: translate(0, 50%);
  background-color: #ffffff;
  color: $blue-principal;
  border-top-left-radius: 1.375rem;
  border-bottom-left-radius: 1.375rem;
  border: 1px solid $blue-principal;
  border-right: none;
  /* Sin borde derecho para unir al botón derecho */
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0px 5px 5px 0px #00000059;
  align-items: center;
  justify-content: center;
  width: 8rem;
}

.see-experience-button {
  position: absolute;
  display: flex;
  bottom: 0;
  left: calc(5.875rem + 8rem);
  /* Ajusta para colocarlo justo a la derecha del primer botón */
  transform: translate(0, 50%);
  background-color: #ffffff;
  color: $blue-principal;
  border-top-right-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
  border: 1px solid $blue-principal;
  gap: 0.5rem;
  /* Sin borde izquierdo para unir al botón izquierdo */
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0px 5px 5px 0px #00000059;
  align-items: center;
  justify-content: center;
  width: 8rem;
}

.cursor-disabled .details-button {
  transform: translate(0, 110%);
}

.testgroup-clip {
  position: absolute;
  display: flex;
  align-self: center;
  top: 0;
  transform: translate(0, -80%);
}

.disabled-feature-icon {
  position: absolute;
  transform: translate(80%, 10%);
  width: 3.9375rem;
  height: 3.9375rem;
  z-index: 2;
}

.disabled-feature-container {
  .details-button {
    transform: translate(0, 50%);
  }
}

.objetivos,
.roles {
  display: flex;
  flex-direction: column;
}

.test-roles-content,
.test-objectives-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  padding: 0 0 0.5rem 0;

  p {
    margin: 0;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
