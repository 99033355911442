.members-configuration-page-background {
  min-height: 100%;
  min-width: 100%;
  background-color: #fff;
}

.members-configuration-page-container {
  height: calc(100vh - 4rem);
  padding: 0 1rem;
}

.members-configurations-page-content {
  min-height: 20rem;
}
