@import '../../variables.scss';
.checkboxButton {
  position: relative;
  input,
  svg {
    width: 1.125rem;
    height: 1.125rem;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: #fff;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 2px $blue-principal;
    &:hover {
      box-shadow: inset 0 0 0 2px lighten($blue-principal, 10%);
    }
    &:checked {
      box-shadow: inset 0 0 0 2px darken($blue-principal, 10%);
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: $blue-principal;
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 0.938rem;
    height: 0.938rem;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 0.125rem;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    --stroke: var(--tick);
    input {
      &:checked {
        /* --s: 11px; */
        & + svg {
          animation: bounce 0.1s linear forwards 0.1s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }
}
@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.radio-button-option {
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.016rem;
  color: #828282;
}
