.modal {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
  overflow: hidden;
}

.modal__wrap {
  margin: auto 0.5rem;
  align-self: start;
  opacity: 0;
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.visible .modal__wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.button-right-modal,
.button-left-modal {
  box-sizing: border-box;
  border: 1px solid #4987be;
}

.button-left-modal {
  background: #ffffff;
  padding: 0.685rem 1.345rem;
}

.button-right-modal {
  background: #4987be;
  padding: 0.685rem 1.345rem;
}

.button-right-modal:disabled,
.button-left-modal:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
}

.text-button-left-modal,
.text-button-right-modal {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin: 0prem 0.25rem;
}

.text-button-left-modal {
  color: #4987be;
}

.text-button-right-modal {
  color: #ffffff;
}

.line-jump {
  white-space: pre-line;
}

.button-cancel-right-modal {
  background: #dc251c;
  padding: 0.685rem 1.345rem;
}

.button-cancel-right-modal,
.button-cancel-left-modal {
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid #dc251c;
}

.text-button-cancel-left-modal {
  color: #dc251c;
}

@media (min-width: 400px) {
  .modal__wrap {
    margin: auto 1rem;
  }

  .container-components-modal {
    margin: auto 1rem;
  }
}

@media (min-width: 700px) {
  .modal__wrap {
    margin: auto 6rem;
  }

  .container-components-modal {
    margin: auto 3.5rem;
  }
}

@media (min-width: 1000px) {
  .modal__wrap {
    margin: auto 14rem;
  }
}

@media (min-width: 1300px) {
  .modal__wrap {
    margin: auto 24rem;
  }
}

@media (min-width: 1600px) {
  .modal__wrap {
    margin: auto 34rem;
  }
}
