.invited-profile-data-icon {
  width: 1.5rem;
  text-align: center;
}

.invited-profile-data-title,
.invited-profile-search,
.invited-profile-data-text {
  color: #4f4f4f;
}

.download-cv-text {
  color: #41479b;
}

.invited-profile-search-name {
  color: #fff;
  background-color: #fd7779;
}

.invited-profile-cv-link,
.invited-profile-cv-link:hover {
  text-decoration: underline;
  color: #4f4f4f;
}

.margin-left-country-custom {
  margin-left: 0.7525rem;
}

.margin-left-phone-custom {
  margin-left: 0.4rem;
}

.margin-left-cv-custom {
  margin-left: 0.5625rem;
}

.ml-custom-mark-icon {
  margin-left: 0.1875rem;
}

.container-about-invited-profile {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 0.5625rem 0.5rem 0.5625rem 1rem;
}

.invited-profile-pass-stage-button {
  background-color: #b1b1b1;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  letter-spacing: 0.04rem;
}

.profile-invitation-img {
  width: 4.65rem;
  height: 4.65rem;
}

.go-back-button {
  border-style: solid;
}
