.profile-search-input-search-container {
  position: relative;
}

.profile-search-input-search {
  padding-right: 3.5rem !important;
}

.profile-search-input-search-btn {
  color: #6b6b6b;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 2px 14px;
  border: none;
  background: none;
}

.profile-search-input-search-btn:visited,
.profile-search-input-search-btn:focus-visible {
  outline: none;
}

.profile-search-input-age-btn {
  border: none;
  background: #6c757d;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: .25rem;
}