.dropdown-btn {
  background-color: transparent;
  border-radius: 0.25rem;
  &:active {
    outline: none;
    border: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.vertical-dots-dropdown {
  list-style: none;
  max-height: 0;
  width: 12rem;
  z-index: 10;
  right: 0;
  box-shadow: 0px 0px 6px 0px #00000029;
  &.open {
    max-height: 12.5rem;
  }
}

.menu-dots {
  height: 1.25rem;
  fill: #757575;
}

.dropdown-option {
  color: #757575;
  list-style-type: none;
  font-size: 0.875rem;
  .icon {
    fill: #757575;
    height: 0.875rem;
  }
  .dropdown-btn:hover {
    background-color: #f2f2f2;
  }
  &:hover {
    color: #1a1a1a;
    .icon {
      fill: #1a1a1a;
    }
  }
}

.dropdown-delete-option {
  color: #757575;
  list-style-type: none;
  font-size: 0.875rem;
  .icon {
    fill: #757575;
    height: 0.875rem;
  }
  .dropdown-btn:hover {
    background-color: #fee8e7;
  }
  &:hover {
    color: #f44336;

    .icon {
      fill: #f44336;
    }
  }
}
