.no-score-bg {
  background-color: #b3b3b3;
}
.assessment-invitation-name {
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: black;
}

.assessment-invitation-name:hover {
  text-decoration: underline;
}

.city-span {
  width: 100%;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.screening-invitation-item-row {
  border-bottom: 1px solid #c8c8c8;
  background: white;
  width: max-content;
  min-width: 100%;

  &:hover {
    background: #f4f4f4;
  }

  .pinned {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 2;
  }
}

.linkedin {
  height: 1.25rem;
  max-width: 1.25rem;
  &.withLink {
    fill: #0a66c2;
  }
  &.withoutLink {
    fill: #828282;
  }
}

.wrapper {
  --sizing: auto-fit;
  display: grid;
  grid-template-columns: repeat(var(--sizing), minmax(100px, 1fr));
  grid-gap: 1rem;
}

.invisible-background {
  background-color: #d9d9d9;
  background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%),
    linear-gradient(225deg, #ffffff 25%, transparent 25%),
    linear-gradient(45deg, #ffffff 25%, transparent 25%),
    linear-gradient(315deg, #ffffff 25%, #d9d9d9 25%);
  background-position: 4px 0, 4px 0, 0 0, 0 0;
  background-size: 4px 4px;
  background-repeat: repeat;
}

.calendar-invitations {
  margin: 0 !important;
  justify-items: center !important;
  align-items: center !important;

  i {
    margin: 0 !important;
  }
}
