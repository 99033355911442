.number-pill-container {
  height: 1.375rem;
  width: 3.3rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.07rem;
  border-radius: 20px;
  padding: 0 0.7rem;
  flex-shrink: 0;
}

.number-pill-no-score-bg {
  background-color: #b3b3b3;
}

.number-pill-green-bg {
  background-color: #5edca7;
}
