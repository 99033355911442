@import '../../src/variables.scss';

.home-container {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-container-gap {
  gap: 0.8rem;
}

.home-display-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 8rem;
  height: 100%;
  width: 100% !important;

  @media (min-width: 1280px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (max-width: 1024px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (max-width: 830px) {
    padding: 0;
  }
}

.home-welcome-text {
  font-size: 1.125rem;
}

.home-display-content {
  height: 100%;
}

.home-section-plan-container {
  width: 88%;
}

.dropdown-no-border {
  width: 100% !important;

  span {
    color: $grey-1 !important;
  }

  path {
    stroke: $grey-1 !important;
  }
}

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.home-rocket-no-active-searches {
  width: 16.5625rem;
}

.no-searches-content {
  padding: 2rem 6.2rem;
  border-radius: 2%;
  line-height: 1.875rem;
  width: 50%;

  @media (max-width: 1200px) {
    width: 88%;
  }
}

.home-button-custom-size {
  padding: 1.5rem 3rem;
  border-radius: 5rem !important;
}

.home-profile-separator {
  border-left: 1px solid #d9d9d9;

  @media (max-width: 992px) {
    border-left: none;
  }
}
