@media (min-width: 576px) {
  .invited-profile-data-card {
    width: 20%;
    min-width: 22rem;
  }

  .invited-profile-stages-card {
    width: 35%;
    min-width: 30rem;
  }
}

.invited-profile-data-card-custom {
  width: 20%;
  min-width: 22rem;
}

.container-invited-profile-ca {
  display: flex;
  flex-direction: row;
}

@media (max-width: 847px) {
  .invited-profile-data-card-custom {
    width: 100%;
    min-width: 22rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .invited-profile-stages-card {
    width: 100%;
    margin-top: 1rem;
  }

  .container-invited-profile-ca {
    flex-direction: column;
  }
}
