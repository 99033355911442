.button-tab {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border: 0;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.button-tab-select:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #a0a0a0;
  width: 100%;
  transform: translateX(-50%);
  bottom: -8px;
  left: 50%;
}

.button-tab-selected:after {
  position: absolute;
  content: '';
  border-bottom: 4px solid rgba(14, 156, 226, 1);
  width: 100%;
  transform: translateX(-50%);
  bottom: -8px;
  left: 50%;
}

.text-tab-select {
  color: rgba(117, 117, 117, 1);
}

.text-tab-selected {
  font-weight: 600;
  color: rgba(14, 156, 226, 1);
}

.bar-custom-color {
  border-radius: 7px 7px 0px 0px;
  height: 16px;
}

.container-picker-customize {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.color-picker-selected {
  width: 32px;
  height: 32px;
  border: 2px solid;
  border-radius: 50%;
  background-color: transparent;
}

.color-picker-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.icon-workspace {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.icon-workspace-selected {
  border: 2px solid;
}

.color-text-icon-picker {
  color: rgba(117, 117, 117, 1);
}

.logo-brand-squared {
  width: 48px;
  height: 48px;
  border-radius: 7px;
}

.min-width-button {
  min-width: 10rem;
}

.test-button-filters-personalize-selected {
  border: 1px solid #4987be;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
}

.test-button-filters-personalize {
  border-radius: 8px;
  border: 1px solid #787878;
}
