.search-modal-card {
  position: fixed !important;
  width: 600px;
  height: 500px;
  max-width: 90%;
  max-height: 80vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: search-modal-animation 0.5s ease;
}

.search-modal-card-body {
  overflow-y: scroll;
}

.search-modal-card-body::after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80px;
  background: linear-gradient(0deg, #fff 30%, hsla(0, 0%, 100%, 0) 80%);
  pointer-events: none;
}

.search-modal-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}

.search-modal-close-button {
  position: absolute;
  right: 20px;
  top: 28px;
  line-height: 24px;
  background: none;
  border: none;
  font-size: 26px;
}

.search-modal-filter-item {
  display: inline-block;
}

.search-modal-input-container {
  min-height: 40px;
  max-height: 40px;
}

.search-modal-input {
  max-width: 85% !important;
}

@keyframes search-modal-animation {
  0% {
    transform: translate(-50%, -50%) translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) translateY(0);
    opacity: 1;
  }
}

@media (min-width: 576px) {
  .search-modal-input {
    max-width: 90% !important;
  }
}
