@import '../../../variables.scss';
.abm-list-item-container {
  display: flex;
  width: 100%;
  padding: 1.125rem 1.375rem;
  margin: 0.5rem 0px;
  border: 2px solid;
}

.abm-list-item-filters-container {
  flex: 1 1 0px;
  justify-content: right;
  align-items: center;
}

.abm-list-item-toggle-container {
  width: 10rem;
}

.abm-list-item-level {
  width: fit-content;
  padding: 0 0.5rem;
  letter-spacing: 0.07rem;
  color: $blue-principal;
  background: #4987be32;
  font-size: 0.813rem;
  border-radius: 2px;
  display: inline-block;
}

.abm-list-item-category {
  color: #fd6466;
  background: #ffe2e3;
  font-size: 0.813rem;
  border-radius: 2px;
  padding: 0 0.5rem;
  letter-spacing: 0.07rem;
  margin-right: 0.5rem;
  min-width: 2rem;
  width: fit-content;
  display: inline-block;
}

.abm-list-tags-container {
  width: 13rem;
}

@media screen and (max-width: 880px) {
  .abm-list-item-filters-container {
    flex: 0 0 0;
    width: fit-content;
  }

  .abm-list-item-category,
  .abm-list-item-level,
  .toggle-left-text,
  .toggle-right-text {
    display: none !important;
  }

  .abm-list-tags-container {
    width: 0;
  }

  .abm-toggle-container,
  .abm-list-item-toggle-container {
    width: fit-content !important;
  }
}

.toggle-left-text,
.toggle-right-text {
  color: #5f5f5f;
}

.abm-list-item-name {
  flex: 1 1 0;
  color: #4b4b4bbf;
  padding: 0;
  min-width: 12.5rem;
}

.abm-list-item-toggle-container {
  width: 10.5rem;
}
