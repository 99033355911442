.new-job-container {
  min-height: 60vh;
}

.create-jobpost-title {
  font-size: 1rem;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
