.text-success-screening {
  color: #222222;
}

.p-container {
  padding-top: 8rem;
  padding-bottom: 8rem;
}



.padding-container-screening {
  padding: 0rem 10rem;
}

@media (max-width: 992px) {
  .padding-container-screening {
    padding: 0rem 6rem;
  }
}

@media (max-width: 576px) {
  .padding-container-screening {
    padding: 0rem 4rem;
  }
}
