.screening-input {
  border: 1px #c9c9c9 solid;
  outline: none;
  padding: 0.3rem 0;
  max-height: 2.225rem;
}

.screening-input::placeholder {
  color: #828282;
  font-size: 0.875rem;
}
