.reset-testgroup-container {
  min-height: 65vh;
}

.reset-testgroup-lists-container {
  height: 24.125rem;
}

.reset-testgroup-modal-data-max-height {
  max-height: 30rem;
}