@import '../../variables.scss';

.text-tab-selected {
  font-weight: 600;
  color: $blue-principal;
}
.without-button {
  border: 0;
  background-color: #fff !important;
}
.button-tab {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border: 0;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.button-tab-select:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #a0a0a0;
  width: 100%;
  transform: translateX(-50%);
  bottom: -8px;
  left: 50%;
}

.button-tab-selected:after {
  position: absolute;
  content: '';
  border-bottom: 4px solid rgba(14, 156, 226, 1);
  width: 100%;
  transform: translateX(-50%);
  bottom: -8px;
  left: 50%;
}

.background-fixed {
  width: 100%;
  height: 13.125rem;
  left: 0px;
  top: 0px;
  background: linear-gradient(92.74deg, $blue-principal 4.89%, #fd7779 100%);
}

.bg-notification {
  background-color: #f4f4f4;
}

.notification-residence-max-width {
  max-width: 15.5rem;
}

.card-notification {
  background: #ffffff;
  border-radius: 0.5rem;
  z-index: 4;
}

.notification-buttons {
  border: 1px solid;
}

.save-notification-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #828282;
  margin-right: 0.815rem;
}

.save-notification {
  top: 0.625rem;
  right: 1rem;
}

.training-description {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #777777;
}

.tech-knowledge-text {
  font-size: 0.9rem;
  color: #4f4f4f;
}

.formations-container {
  max-width: 31.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  transition: 0.5s;
}

.residence-container-max-width {
  max-width: 12.5rem;
}

.formations-container.show-formations {
  transition: 0.5s;
  max-height: 62.5rem;
}

.formations-container-max-height {
  max-height: 9.5rem;
}

.notification-videos-container {
  width: 100%;
}

.notification-name-container {
  width: 100%;
}

.notification-container {
  max-width: 53rem;
}

@media (min-width: 992px) {
  .notification-left-column {
    max-width: 20rem;
    width: 40%;
  }

  .notification-right-column {
    width: 50%;
  }

  .notification-videos-container {
    min-width: 25rem;
    width: 50%;
  }

  .notification-name-container {
    border-bottom: 1px solid #e0e0e0;
    width: 50%;
  }

  .notification-name-title {
    font-size: 2rem;
  }
}
