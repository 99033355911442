.screening-input-delete-icon {
  background: none;
  border: none;
  max-height: 2.225rem;
}

.link-custom-email {
  text-decoration: underline;
  color: #222222;
}

.link-custom-email:hover {
  text-decoration: underline;
}

.calendar-invitations {
  margin: 0 !important;
  justify-items: center !important;
  align-items: center !important;
  padding: 5px !important;
  width: 7rem !important;
  i {
    margin: 0 !important;
  }
}
