.scoreDistribution-chart-container {
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.scoreDistribution-chart-area-container {
  position: relative;
  width: 10%;
}

.scoreDistribution-area-width {
  width: 100%;
  transition: height 0.3s ease;
}

.axis text {
  font-size: 12px;
  font-weight: 700;
}

.axis line {
  stroke: #d9d9d9;
}

