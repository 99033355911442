.ordering-container-triangles {
  height: 0.8rem;
  left: calc(100% + 0.2rem);
  top: -0.27rem;
}

.ordering-asc,
.ordering-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.ordering-desc[data-active="true"] {
  border-top: 5px solid rgb(0, 0, 0);
}

.ordering-desc[data-active="false"] {
  border-top: 5px solid rgba(0, 0, 0, 0.3);
}

.ordering-asc[data-active="true"] {
  border-bottom: 5px solid rgb(0, 0, 0);
}

.ordering-asc[data-active="false"] {
  border-bottom: 5px solid rgb(0, 0, 0, 0.3);
}
